/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Center,
  Drawer,
  Flex,
  Grid,
  Group,
  ScrollArea,
  Table,
  Text,
  TextInput,
  Title
} from '@mantine/core'
import {
  IconAlertTriangle,
  IconCalendar,
  IconCheck,
  IconExclamationCircle,
  IconEye,
  IconPlus,
  IconTrash,
  IconX
} from '@tabler/icons-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  bulkUpsert,
  getAllDataV3,
  updateRecord
} from '../../services/common.service'
import SelectComp from '../../commonComponents/components/select/SelectComp'
import dayjs from 'dayjs'
import DateInputComp from '../../commonComponents/components/dateInput/DateInputComp'
import TextInputComp from '../../commonComponents/components/textInput/TextInputComp'
import {
  checkOldJourneyErrors,
  clearJourneyStore,
  getFormattedJourneyDataForUpdate,
  useJourneyLegInventoryStore,
  useJourneyLegsStore,
  useJourneyStore,
  useSectorStore
} from '../../store/journeyStore'
import { getCabinUsers } from '../../services/employees.service'
import TimeInputComp from '../../commonComponents/components/dateInput/TimeInputComp'
import NumberInputComp from '../../commonComponents/components/numberInput/NumberInputComp'
import { notifications } from '@mantine/notifications'

const notificationIdJourney = 'edit-journey'
const notificationIdLeg = 'edit-journey-leg'
const notificationIdInventory = 'edit-journey-inventory'

const LegData = ({
  position,
  usersQuery,
  activeLeg,
  disabled,
  journeyDate,
  airportsQuery,
  aircraftQueryData,
  onViewDetails,
  isJourneyStarted,
  journeyNumber
}) => {
  const { legs, setLegsByIndex, legErrors, addNewLeg, removeLeg } =
    useJourneyLegsStore(state => state)
  const sectorState = useSectorStore(state => state)
  const index = position - 1
  const curLeg = legs?.[index]
  console.log(curLeg)

  if (!curLeg) {
    if (disabled || isJourneyStarted) return null
    if (legs.length + 1 === position) {
      return (
        <Table.Tr>
          <Table.Td colSpan={7}>
            <Flex justify={'center'}>
              <Button
                variant='light'
                onClick={addNewLeg}
                leftSection={<IconPlus size={14} />}
              >
                Add Leg
              </Button>
            </Flex>
          </Table.Td>
        </Table.Tr>
      )
    }
    return null
  }

  let disableEdit = disabled
  if (!disableEdit) {
    disableEdit = dayjs(new Date()).isAfter(
      dayjs(`${journeyDate} ${curLeg?.departure_time}`).toDate()
    )

    if (curLeg.completed_by_scc || curLeg.completed_by_cc) disableEdit = true
  }

  const handleChange = (field, val) => {
    const item = legs?.[index]
    curLeg[field] = val
    setLegsByIndex(index, item)
  }

  const toAirports = airportsQuery.data?.filter(
    item => item?.value !== curLeg?.depart_airport_id
  )

  return (
    <Table.Tr bg={activeLeg === position ? 'primary.1' : ''}>
      <Table.Td>{position}</Table.Td>
      <Table.Td>
        <Center>{journeyNumber}</Center>
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          readOnly={disableEdit}
          data={aircraftQueryData}
          placeholder={'Choose Aircraft'}
          variant={disableEdit ? 'filled' : 'default'}
          value={curLeg?.aircraft_id}
          error={legErrors?.[index]?.aircraft_id}
          setValue={val => handleChange('aircraft_id', val)}
        />
      </Table.Td>
      <Table.Td>
        <TextInputComp
          isRequired
          readOnly={disableEdit}
          variant={disableEdit ? 'filled' : 'default'}
          placeholder={'Flight no'}
          value={curLeg?.flight_number}
          error={legErrors?.[index]?.flight_number}
          setValue={e => handleChange('flight_number', e?.toUpperCase())}
        />
      </Table.Td>
      <Table.Td>
        <TimeInputComp
          isRequired
          showPicker
          readOnly={disableEdit || isJourneyStarted}
          variant={disableEdit || isJourneyStarted ? 'filled' : 'default'}
          value={curLeg?.departure_time}
          error={legErrors?.[index]?.departure_time}
          setValue={e => {
            handleChange('departure_time', e?.target?.value)
          }}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          readOnly={disableEdit || isJourneyStarted}
          variant={disableEdit || isJourneyStarted ? 'filled' : 'default'}
          placeholder={'From'}
          data={airportsQuery.data}
          error={legErrors?.[index]?.depart_airport_id}
          value={curLeg?.depart_airport_id}
          setValue={(val, _option) => {
            handleChange('depart_airport_id', val)
            let s = {
              from: _option.airport_code
            }
            if (sectorState.sector[index]) {
              s.to = sectorState.sector[index]?.to
            }
            sectorState.setSector(index, s)
          }}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          readOnly={disableEdit || isJourneyStarted}
          variant={disableEdit || isJourneyStarted ? 'filled' : 'default'}
          placeholder={'To'}
          data={toAirports}
          error={legErrors?.[index]?.arrive_airport_id}
          value={curLeg?.arrive_airport_id}
          setValue={(val, _option) => {
            handleChange('arrive_airport_id', val)
            let s = {
              to: _option.airport_code
            }
            if (sectorState.sector[index]) {
              s.from = sectorState.sector[index]?.from
            }
            sectorState.setSector(index, s)
          }}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          readOnly={disableEdit}
          variant={disableEdit ? 'filled' : 'default'}
          placeholder={'SCC'}
          data={usersQuery?.data || []}
          error={legErrors?.[index]?.senior_cabin_crew_id}
          value={curLeg?.senior_cabin_crew_id}
          setValue={val => handleChange('senior_cabin_crew_id', val)}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          readOnly={disableEdit}
          variant={disableEdit ? 'filled' : 'default'}
          placeholder={'CC'}
          data={usersQuery?.data || []}
          error={legErrors?.[index]?.cabin_crew_id}
          value={curLeg?.cabin_crew_id}
          setValue={val => handleChange('cabin_crew_id', val)}
        />
      </Table.Td>
      <Table.Td>
        <Flex direction={'row'} gap={'xs'} align={'center'}>
          <ActionIcon
            variant={'subtle'}
            // color={'primary'}
            onClick={() => {
              onViewDetails({
                ...curLeg,
                leg_number: position
              })
            }}
          >
            <IconEye size={16} />
          </ActionIcon>
          {position > 2 && position === legs.length && !disableEdit ? (
            <ActionIcon
              variant={'subtle'}
              color={'red'}
              onClick={() => {
                removeLeg(index)
                sectorState.removeSector()
                onViewDetails({
                  ...legs[index - 1],
                  leg_number: position
                })
              }}
            >
              <IconTrash size={16} />
            </ActionIcon>
          ) : (
            <ActionIcon variant={'transparent'} onClick={() => null} />
          )}
        </Flex>
      </Table.Td>
    </Table.Tr>
  )
}

const JourneyLegs = ({
  id,
  journeyId,
  legData,
  selectedLeg,
  onViewDetails,
  disabled,
  isJourneyStarted,
  aircraftQueryData,
  journeyNumber
}) => {
  const setLegs = useJourneyLegsStore(state => state.setLegs)
  const airportsQuery = useQuery({
    queryKey: ['all-airports'],
    queryFn: () =>
      getAllDataV3({
        source: 'airports',
        page: -1,
        filter: { is_active: true }
      }),
    select: data => {
      return data?.data?.map(items => {
        return {
          ...items,
          label: items?.airport_code,
          value: `${items?.id}`
        }
      })
    }
  })
  const legsQuery = useQuery({
    queryKey: ['all-legs', journeyId],
    queryFn: () =>
      getAllDataV3({
        source: 'journey_leg',
        page: -1,
        filter: { journey_id: journeyId },
        sort: [{ id: 'leg_number', desc: false }]
      })
  })

  const usersQuery = useQuery({
    queryKey: ['all-cabin-users'],
    queryFn: () => getCabinUsers({ page: -1 }),
    select: data => {
      return data?.data?.map(items => {
        return {
          ...items,
          label: `${items?.emp_name} (${items?.emp_code})`,
          value: `${items?.id}`
        }
      })
    }
  })

  useEffect(() => {
    if (legsQuery.data?.data?.length) {
      const result = legsQuery.data?.data?.map(item => {
        return {
          id: item.id,
          leg_number: item.leg_number,
          journey_number: item.journey_number,
          is_last_leg: item.is_last_leg,
          flight_number: item.flight_number,
          departure_time: item.departure_time,
          depart_airport_id: String(item.depart_airport_id?.id),
          arrive_airport_id: String(item.arrive_airport_id?.id),
          senior_cabin_crew_id: String(item.senior_cabin_crew_id?.id),
          cabin_crew_id: String(item.cabin_crew_id?.id),
          aircraft_id: String(item.aircraft_id?.id),
          completed_by_scc: item.completed_by_scc,
          completed_by_cc: item.completed_by_cc
        }
      })
      setLegs(result)
    }
  }, [legsQuery.data?.data])

  return (
    <Box>
      <Table verticalSpacing={8} horizontalSpacing={4}>
        <Table.Thead>
          <Table.Tr c='gray.6'>
            <Table.Td>#</Table.Td>
            <Table.Td align='center' w={'8%'}>
              Journey #
            </Table.Td>
            <Table.Td align='center' w={'14%'}>
              Aircraft
            </Table.Td>
            <Table.Td align='center' w={'12%'}>
              Flight No
            </Table.Td>
            <Table.Td align='center' w={'12%'}>
              Dep. Time
            </Table.Td>
            <Table.Td align='center' w={'10%'}>
              From
            </Table.Td>
            <Table.Td align='center' w={'10%'}>
              To
            </Table.Td>
            <Table.Td align='center' w={'16%'}>
              SCC
            </Table.Td>
            <Table.Td align='center' w={'10%'}>
              CC
            </Table.Td>
            <Table.Td align='center'></Table.Td>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <LegData
            position={1}
            disabled={disabled}
            isJourneyStarted={isJourneyStarted}
            journeyDate={legData?.journey_date}
            activeLeg={selectedLeg?.leg_number || legData.leg_number}
            airportsQuery={airportsQuery}
            onViewDetails={onViewDetails}
            usersQuery={usersQuery}
            aircraftQueryData={aircraftQueryData}
            journeyNumber={journeyNumber}
          />
          <LegData
            position={2}
            disabled={disabled}
            isJourneyStarted={isJourneyStarted}
            journeyDate={legData?.journey_date}
            activeLeg={selectedLeg?.leg_number || legData.leg_number}
            airportsQuery={airportsQuery}
            onViewDetails={onViewDetails}
            usersQuery={usersQuery}
            aircraftQueryData={aircraftQueryData}
          />
          <LegData
            position={3}
            disabled={disabled}
            isJourneyStarted={isJourneyStarted}
            journeyDate={legData?.journey_date}
            activeLeg={selectedLeg?.leg_number || legData.leg_number}
            airportsQuery={airportsQuery}
            onViewDetails={onViewDetails}
            usersQuery={usersQuery}
            aircraftQueryData={aircraftQueryData}
          />
          <LegData
            position={4}
            disabled={disabled}
            isJourneyStarted={isJourneyStarted}
            journeyDate={legData?.journey_date}
            activeLeg={selectedLeg?.leg_number || legData.leg_number}
            airportsQuery={airportsQuery}
            onViewDetails={onViewDetails}
            usersQuery={usersQuery}
            aircraftQueryData={aircraftQueryData}
          />
          <LegData
            position={5}
            disabled={disabled}
            isJourneyStarted={isJourneyStarted}
            journeyDate={legData?.journey_date}
            activeLeg={selectedLeg?.leg_number || legData.leg_number}
            airportsQuery={airportsQuery}
            onViewDetails={onViewDetails}
            usersQuery={usersQuery}
            aircraftQueryData={aircraftQueryData}
          />
        </Table.Tbody>
      </Table>
    </Box>
  )
}

const ProductListing = ({ id, journeyId, legData, selectedLeg, disabled }) => {
  const { inventory, inventoryError, setInventory, setInventoryItem } =
    useJourneyLegInventoryStore(state => state)
  const firstLeg = useJourneyLegsStore(state => state.legs[0])
  const legProductsQuery = useQuery({
    queryKey: ['leg-products', selectedLeg?.id || id],
    queryFn: () =>
      getAllDataV3({
        source: 'journey_leg_inventory',
        page: -1,
        filter: { journey_leg_id: selectedLeg?.id || id }
      })
  })
  const productsQuery = useQuery({
    enabled: legProductsQuery.status === 'success',
    queryKey: ['all-products'],
    queryFn: () =>
      getAllDataV3({
        source: 'products',
        page: -1,
        filter: { is_active: true },
        sort: [{ id: 'product_name', desc: false }]
      })
  })

  useEffect(() => {
    if (productsQuery.data?.data) {
      const legProducts = legProductsQuery.data?.data
      const d = productsQuery.data?.data?.map(item => {
        const legPd = legProducts?.find(
          legProduct => legProduct?.product_id?.id === item?.id
        )

        return {
          ...item,
          old: legPd,
          opening: legPd?.opening_scc + legPd?.opening_cc || 0,
          closing: legPd?.closing_scc + legPd?.closing_cc || 0
        }
      })
      setInventory(d)
    }
  }, [productsQuery.data, legProductsQuery.data])

  const handleChange = (e, item) => {
    item.opening = e
    setInventoryItem(item)
  }
  let disableEdit = disabled
  if (!disableEdit) {
    disableEdit = (selectedLeg?.leg_number || legData?.leg_number) !== 1
  }
  if (!disableEdit) {
    disableEdit = dayjs(new Date()).isAfter(
      dayjs(`${legData?.journey_date} ${firstLeg?.departure_time}`).toDate()
    )
  }

  return (
    <Box>
      {inventoryError ? (
        <Alert color={'red'} icon={<IconAlertTriangle size={16} />}>
          Kindly add opening for a product
        </Alert>
      ) : null}
      <Table>
        <Table.Thead bg={'primary.0'}>
          <Table.Tr c='gray.6' fw={600}>
            <Table.Td c={'primary'} fw={600}>
              Items (for Leg #{selectedLeg?.leg_number || legData?.leg_number})
            </Table.Td>
            <Table.Td align='right'>Available</Table.Td>
            <Table.Td align='center'>
              Uplifted
              <Box>
                <Text size='xs'>{firstLeg?.flight_number}</Text>
              </Box>
            </Table.Td>
            <Table.Td align='center'>Closing</Table.Td>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {inventory?.map((item, index) => (
            <Table.Tr key={item.product_name}>
              <Table.Td>{item.product_name}</Table.Td>
              <Table.Td align='right'>{item.available_quantity}</Table.Td>
              <Table.Td align='center'>
                <NumberInputComp
                  w={80}
                  isRequired
                  readOnly={disableEdit}
                  variant={disableEdit ? 'filled' : 'default'}
                  hideControls={disableEdit}
                  withKeyboardEvents
                  min={0}
                  max={item.available_quantity}
                  clampBehavior='strict'
                  value={item?.opening}
                  styles={{ input: { textAlign: 'center' } }}
                  setValue={e => handleChange(e, item)}
                />
              </Table.Td>
              <Table.Td align='center'>
                <NumberInputComp
                  w={80}
                  readOnly
                  variant='filled'
                  value={item?.closing}
                  setValue={() => null}
                  styles={{ input: { textAlign: 'center' } }}
                />
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Box>
  )
}

const EditJourneyForm = ({
  data,
  opened,
  size = '75%',
  onClose,
  onSuccess
}) => {
  const [selectedLeg, setSelectedLeg] = useState()
  const journeyState = useJourneyStore(state => state)
  const firstLeg = useJourneyLegsStore(state => state.legs[0])
  const sectorPattern = useSectorStore(state => ({
    pattern: state.pattern,
    setPattern: state.setPattern,
    updateSector: state.updateSector
  }))
  console.log(data)

  useEffect(() => {
    if (data?.journey_id) {
      const j = data?.journey_id
      journeyState.setJourney({
        id: j?.id,
        journey_number: j?.journey_number,
        journey_date: j?.journey_date,
        journey_status: j?.journey_status,
        aircraft_id: String(j?.aircraft_id)
      })
      sectorPattern.setPattern(j?.sector_pattern)
    }
  }, [data])

  const aircraftQuery = useQuery({
    enabled: opened && Boolean(data?.id),
    queryKey: ['all-aircrafts'],
    queryFn: () =>
      getAllDataV3({
        source: 'aircrafts',
        page: -1,
        filter: { is_active: true }
      }),
    select: data => {
      return data?.data?.map(items => {
        return {
          label: items?.tail_reg_no,
          value: `${items?.id}`
        }
      })
    }
  })

  const saveJourneyMutation = useMutation({
    mutationFn: body => updateRecord({ source: 'journey', body }),
    onSuccess: () => {
      notifications.update({
        id: notificationIdJourney,
        title: 'Journey Details Updated',
        message: 'Please wait till we update leg info',
        color: 'green',
        loading: false,
        autoClose: 3000,
        icon: <IconCheck />
      })
      // onSuccess()
    },
    onError: e => {
      console.log(e)
      notifications.update({
        id: notificationIdJourney,
        title: 'Sorry!',
        message: e || 'Something went wrong',
        color: 'red',
        loading: false,
        autoClose: 3000,
        icon: <IconExclamationCircle />
      })
    }
  })

  const saveJourneyLegMutation = useMutation({
    mutationFn: body => bulkUpsert({ source: 'journey_leg', body }),
    onSuccess: () => {
      notifications.update({
        id: notificationIdLeg,
        title: 'Journey Legs Updated',
        message: 'Successfully updated',
        color: 'green',
        loading: false,
        autoClose: 3000,
        icon: <IconCheck />
      })
    },
    onError: e => {
      console.log(e)
      notifications.update({
        id: notificationIdLeg,
        title: 'Sorry!',
        message: e || 'Something went wrong',
        color: 'red',
        loading: false,
        autoClose: 3000,
        icon: <IconExclamationCircle />
      })
    }
  })

  const saveJourneyLegInventoryMutation = useMutation({
    mutationFn: body => bulkUpsert({ source: 'journey_leg_inventory', body }),
    onSuccess: () => {
      notifications.update({
        id: notificationIdInventory,
        title: 'Opening Items are Updated',
        message: 'Successfully updated',
        color: 'green',
        loading: false,
        autoClose: 3000,
        icon: <IconCheck />
      })
    },
    onError: e => {
      console.log(e)
      notifications.update({
        id: notificationIdInventory,
        title: 'Sorry!',
        message: e || 'Something went wrong',
        color: 'red',
        loading: false,
        autoClose: 3000,
        icon: <IconExclamationCircle />
      })
    }
  })

  if (!data) return null

  function handleSave () {
    if (
      !checkOldJourneyErrors({
        currentLeg: selectedLeg?.leg_number || data?.leg_number || 1
      })
    ) {
      const result = getFormattedJourneyDataForUpdate({
        currentLeg: selectedLeg?.leg_number || data?.leg_number || 1
      })
      console.log(result)
      if (result.updateJourney) {
        const reqBody = result.journey
        notifications.show({
          id: notificationIdJourney,
          title: 'Updating Journey Details...',
          message: 'Please do not close anything.',
          loading: true,
          autoClose: false
        })
        saveJourneyMutation.mutate(
          {
            ...reqBody,
            sector_pattern: sectorPattern.pattern
          },
          {
            onSuccess: () => {
              if (result.updateLegs) {
                const reqBody = result.journey_leg
                notifications.show({
                  id: notificationIdLeg,
                  title: 'Updating Journey Legs...',
                  message: 'Please do not close anything.',
                  loading: true,
                  autoClose: false
                })
                saveJourneyLegMutation.mutate(reqBody)
              }
            },
            onSettled: () => {
              onSuccess()
            }
          }
        )
      } else if (!result.updateJourney && result.updateLegs) {
        if (result.updateLegs) {
          const reqBody = result.journey_leg
          notifications.show({
            id: notificationIdLeg,
            title: 'Updating Journey Legs...',
            message: 'Please do not close anything.',
            loading: true,
            autoClose: false
          })
          saveJourneyLegMutation.mutate(reqBody, {
            onSuccess: () => {
              onSuccess()
            }
          })
        }
      }

      if (result.updateInventory) {
        const reqBody = result.journey_leg_inventory
        notifications.show({
          id: notificationIdInventory,
          title: 'Updating Opening Items...',
          message: 'Please do not close anything.',
          loading: true,
          autoClose: false
        })
        saveJourneyLegInventoryMutation.mutate(reqBody, {
          onSuccess: () => {
            onSuccess()
          }
        })
      }
    }
  }

  const handleClose = () => {
    clearJourneyStore()
    setSelectedLeg({})
    onClose()
  }

  let isPast = dayjs(new Date()).isAfter(
    dayjs(data?.journey_date + ' ' + data?.departure_time).toDate()
  )
  let isJourneyStarted = isPast
  if (!isPast) {
    isJourneyStarted = dayjs(new Date()).isAfter(
      dayjs(data?.journey_date + ' ' + firstLeg?.departure_time).toDate()
    )
  }

  return (
    <Drawer
      opened={opened}
      onClose={handleClose}
      title={
        <Text size={'md'} fw={600}>
          Edit Journey
        </Text>
      }
      padding='md'
      size={size}
      position='right'
      radius='md'
      offset={8}
      overlayProps={{ backgroundOpacity: 0.5, blur: 1 }}
    >
      <Flex direction={'column'} gap={4}>
        <Box flex={1}>
          <Grid>
            <Grid.Col span={3}>
              <DateInputComp
                isRequired
                label={'Journey Date'}
                readOnly={isPast || isJourneyStarted}
                variant={isPast || isJourneyStarted ? 'filled' : 'default'}
                value={
                  journeyState?.journey?.journey_date
                    ? new Date(journeyState?.journey?.journey_date)
                    : null
                }
                rightSection={<IconCalendar size={16} />}
                placeholder={'Select Journey Date'}
                error={journeyState?.journeyErrors?.journey_date}
                // minDate={new Date()}
                // maxDate={dayjs(new Date()).add(15, 'day').toDate()}
                setValue={e => {
                  journeyState.setJourney({
                    ...journeyState?.journey,
                    updateJourney: true,
                    journey_date: e ? dayjs(e).format('YYYY-MM-DD') : null
                  })
                }}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <SelectComp
                isRequired
                label={'Aircraft'}
                readOnly={isPast || isJourneyStarted}
                variant={isPast || isJourneyStarted ? 'filled' : 'default'}
                placeholder={'Choose Aircraft'}
                data={aircraftQuery.data}
                value={journeyState?.journey?.aircraft_id}
                error={journeyState?.journeyErrors.aircraft_id}
                setValue={val => {
                  journeyState.setJourney({
                    ...journeyState?.journey,
                    updateJourney: true,
                    aircraft_id: val
                  })
                }}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <TextInput
                readOnly
                variant='filled'
                label={'Sector Pattern'}
                value={sectorPattern.pattern}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              {journeyState?.journey?.id ? (
                <JourneyLegs
                  id={data?.id}
                  legData={data}
                  disabled={isPast}
                  isJourneyStarted={isJourneyStarted}
                  journeyId={journeyState?.journey?.id}
                  selectedLeg={selectedLeg}
                  aircraftQueryData={aircraftQuery.data || []}
                  onViewDetails={e => setSelectedLeg(e)}
                  journeyNumber={data?.journey_number}
                />
              ) : null}
            </Grid.Col>
            <Grid.Col span={6}>
              {journeyState?.journey?.id ? (
                <ProductListing
                  id={data?.id}
                  disabled={isPast}
                  legData={data}
                  isJourneyStarted={isJourneyStarted}
                  journeyId={journeyState?.journey?.id}
                  selectedLeg={selectedLeg}
                />
              ) : null}
            </Grid.Col>
          </Grid>
        </Box>
        <Grid hidden={isPast}>
          <Grid.Col span={12}>
            <Group justify='flex-start' mt={'md'}>
              <Group>
                <Button
                  w={200}
                  leftSection={<IconCheck size={16} />}
                  onClick={handleSave}
                  loading={saveJourneyMutation.isLoading}
                >
                  Save
                </Button>
                <Button
                  w={200}
                  color='gray'
                  variant='light'
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Group>
            </Group>
          </Grid.Col>
        </Grid>
      </Flex>
    </Drawer>
  )
}

export default EditJourneyForm
