import { Button, Drawer, Group, Modal, Text, Title } from '@mantine/core'
import DateInputComp from '../../commonComponents/components/dateInput/DateInputComp'
import SelectComp from '../../commonComponents/components/select/SelectComp'
import { IconCalendar, IconExclamationCircle } from '@tabler/icons-react'
import ReactTable from '../../commonComponents/reactTable/ReactTable'
import { createColumnHelper } from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getCabinUsers } from '../../services/employees.service'
import { getAllDataV4, updateRecord } from '../../services/common.service'
import { useState } from 'react'
import dayjs from 'dayjs'
import { notifications } from '@mantine/notifications'

const JourneyEditSearch = ({ opened, onClose, size = '50%' }) => {
  const navigate = useNavigate()
  const columnHelper = createColumnHelper()
  const [confirmationModal, setConfirmationModal] = useState()
  const [errorObj, setErrorObj] = useState({
    journey_date: null,
    senior_cabin_crew_id: null,
    cabin_crew_id: null
  })
  const [searchData, setSearchData] = useState({
    journey_date: dayjs().format('YYYY-MM-DD'),
    senior_cabin_crew_id: null,
    cabin_crew_id: null
  })

  const usersQuery = useQuery({
    queryKey: ['all-cabin-users'],
    queryFn: () => getCabinUsers({ page: -1 }),
    select: data => {
      return data?.data
        ?.filter(item => ['SCC', 'CC'].includes(item?.emp_role_id?.role_name))
        ?.map(items => {
          return {
            ...items,
            label: `${items?.emp_name} (${items?.emp_code})`,
            value: `${items?.id}`
          }
        })
    }
  })

  const getAllJourneysQuery = useMutation({
    mutationFn: () =>
      getAllDataV4({
        source: 'sales-edit-eligible-journeys',
        ...searchData
      })
  })

  const addManualEntryQuery = useMutation({
    mutationFn: body => updateRecord({ source: 'journey', body }),
    onSuccess: () => {
      navigate(`edit/${confirmationModal?.id}`)
    }
  })

  const column = [
    columnHelper.accessor('sector_pattern', {
      header: 'Sector Patten'
    }),
    columnHelper.accessor('aircraft', {
      header: 'Aircraft'
    }),
    columnHelper.accessor('departure_time', {
      header: 'Departure Time'
    }),
    columnHelper.accessor('opening', {
      header: 'Opening'
    }),
    columnHelper.accessor('closing', {
      header: 'Closing'
    }),
    columnHelper.accessor('journey_id', {
      header: 'Action',
      cell: ({ row }) => (
        <Button
          variant='subtle'
          size='xs'
          onClick={() =>
            setConfirmationModal({
              modal: true,
              id: row?.original?.journey_id,
              data: row?.original
            })
          }
        >
          Edit Sales
        </Button>
      )
    })
  ]

  const handleSearch = () => {
    if (
      searchData?.cabin_crew_id &&
      searchData?.journey_date &&
      searchData?.senior_cabin_crew_id
    ) {
      setErrorObj({})
      getAllJourneysQuery?.mutate()
    } else {
      let error = {}
      if (!searchData?.cabin_crew_id) {
        error = { ...error, cabin_crew_id: true }
      }
      if (!searchData?.journey_date) {
        error = { ...error, journey_date: true }
      }
      if (!searchData?.senior_cabin_crew_id) {
        error = { ...error, senior_cabin_crew_id: true }
      }
      setErrorObj(error)
      notifications.show({
        title: 'Error',
        message: 'Please select all the fields',
        color: 'red',
        icon: <IconExclamationCircle />,
        autoClose: 3000
      })
    }
  }

  return (
    <Drawer
      opened={opened}
      onClose={() => {
        onClose()
        getAllJourneysQuery?.reset()
      }}
      title={
        <Title size={'md'} fw={600}>
          Edit Sales
        </Title>
      }
      padding='md'
      size={size}
      position='right'
      radius='md'
      offset={8}
      overlayProps={{ backgroundOpacity: 0.5, blur: 1 }}
    >
      <Group justify='space-between' align='flex-end'>
        <DateInputComp
          label={'Journey Date'}
          rightSection={<IconCalendar strokeWidth={1} size={20} />}
          value={
            searchData?.journey_date ? new Date(searchData?.journey_date) : null
          }
          maxDate={new Date()}
          // minDate={new Date(dayjs(new Date()).subtract(30, 'day'))}
          allowDeselect={false}
          setValue={value => {
            setSearchData({
              ...searchData,
              journey_date: dayjs(value).format('YYYY-MM-DD')
            })
            setErrorObj({ ...errorObj, journey_date: null })
          }}
          error={errorObj?.journey_date}
        />
        <SelectComp
          label={'SCC'}
          options={
            searchData?.cabin_crew_id
              ? usersQuery?.data?.filter(
                  item => item?.id != searchData?.cabin_crew_id
                ) || []
              : usersQuery?.data || []
          }
          setValue={value => {
            setSearchData({
              ...searchData,
              senior_cabin_crew_id: value
            })
            setErrorObj({ ...errorObj, senior_cabin_crew_id: null })
          }}
          error={errorObj?.senior_cabin_crew_id}
        />
        <SelectComp
          label={'CC'}
          options={
            searchData?.senior_cabin_crew_id
              ? usersQuery?.data?.filter(
                  item => item?.id != searchData?.senior_cabin_crew_id
                ) || []
              : usersQuery?.data || []
          }
          setValue={value => {
            setSearchData({
              ...searchData,
              cabin_crew_id: value
            })
            setErrorObj({ ...errorObj, cabin_crew_id: null })
          }}
          error={errorObj?.cabin_crew_id}
        />
        <Button loading={getAllJourneysQuery?.isPending} onClick={handleSearch}>
          Search
        </Button>
      </Group>
      <ReactTable
        columnData={column}
        rowData={getAllJourneysQuery?.data?.data}
      />
      <Modal
        opened={confirmationModal?.modal}
        onClose={setConfirmationModal}
        title={<Title size={'md'}>Add/Edit Sales?</Title>}
      >
        <Text>
          You are trying to edit the sales for this journey. Confirm to proceed.
        </Text>
        <Group mt={'md'} justify='flex-end'>
          <Button
            size='xs'
            variant='light'
            color='gray'
            onClick={setConfirmationModal}
          >
            Cancel
          </Button>
          <Button
            size='xs'
            onClick={() => {
              addManualEntryQuery?.mutate({
                id: confirmationModal?.id,
                manual_sales_entry: true
              })
            }}
            loading={addManualEntryQuery?.isPending}
          >
            Confirm
          </Button>
        </Group>
      </Modal>
    </Drawer>
  )
}

export default JourneyEditSearch
