import { Button, Grid, Group, ScrollArea, Skeleton, Text } from "@mantine/core";
import ModalComp from "../components/modal/ModalComp";
import { useEffect, useMemo } from "react";
import FilterFields from "./FilterFields";
import { COLORS } from "../../constants/colors";
import { displayNotification } from "../notifications/displayNotification";

const FilterModal = ({
    builderData,
    builderDataLoading = false,
    opened,
    onClose,
    title,
    pagination,
    setPagination,
    value,
    setValue,
}) => {

    useEffect(() => {
        console.log(builderData);
        console.log(allowedFields);
        console.log(pagination?.filter);
        if (pagination?.filter !== undefined 
            && pagination?.filter !== null
            && Object.entries(pagination?.filter)?.length) {
            setValue({
                ...value,
                data: pagination?.filter
            });
        }
        // console.log(value);
    }, [opened])
    
    const allowedFields = useMemo(() => {
        return builderData?.filter(item => 
            !item?.hidden && 
            (item?.interface === 'DATETIME' || item?.interface === 'DATE' || 
                item?.interface === 'DROPDOWN' || item?.interface === 'CHECKBOX' ||
                item?.interface === 'FORM_SEARCH'
            ));
    }, [builderData])

    const handleFilterSubmit = () => {
        // console.log(value);
        if (value?.data !== undefined 
            && value?.data !== null 
            && Object.entries(value?.data)?.length) {
            setPagination({
                ...pagination,
                filter: value?.data
            })
            onClose();
        } else {
            displayNotification({
                message: 'Please add filters',
                variant: 'warning'
            })
        }
    };

    const handleClear = () => {
        console.log(value);
        setValue({
            ...value,
            data: {}
        });
        setPagination({
            ...pagination,
            filter: {}
        });
        onClose();
    };

    return (
        <ModalComp
            opened={opened}
            closeOnClickOutside={false}
            onClose={() => {
                onClose()
            }}
            title={title}
            radius={'md'}
            size={'xl'}
        >
            <ScrollArea.Autosize
                mah={'70vh'}
                scrollbars='y'
                offsetScrollbars
            >
                <Grid>
                    {allowedFields?.length ? (
                        allowedFields?.map((item, index) => (
                            <Grid.Col 
                                span={6} 
                                key={index}
                                style={{
                                    alignContent: 'end'
                                }}
                            >
                                <FilterFields
                                    item={item}
                                    key={index}
                                    value={value}
                                    setValue={setValue}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                />
                            </Grid.Col>
                        ))
                    ) : (
                        <Grid.Col span={12} my={100}>
                            <Text c={'dimmed'} size='xs' ta={'center'}>
                                No Fields to display!
                            </Text>
                        </Grid.Col>
                    )}
                </Grid>
                {allowedFields?.length ? (
                    <Group justify='flex-end' gap={10} mt={'xl'}>
                        <Button
                            variant='outline'
                            color='blue'
                            onClick={() => {
                                handleClear()
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            color={COLORS.primary}
                            onClick={() => handleFilterSubmit(value)}
                        >
                            Submit
                        </Button>
                    </Group>
                ) : null}
            </ScrollArea.Autosize>
        </ModalComp>
    );
};

export default FilterModal;