/**
 * Compare two objects to see if they are equal.
 * This function recursively checks if the objects are the same reference,
 * are not null or undefined, and have the same keys and values.
 * @param {object} obj1 The first object to compare.
 * @param {object} obj2 The second object to compare.
 * @returns {boolean} true if the objects are equal, false if they are not.
 */
export const compareObject = (obj1, obj2) => {
  // Check if both are the same reference
  if (obj1 === obj2) return true

  // Check if either is null or not an object
  if (
    obj1 === null ||
    obj2 === null ||
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object'
  )
    return false

  // Compare keys
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  // Recursively compare values
  return keys1.every(key => compareObject(obj1[key], obj2[key]))
}

/**
 * Groups an array of objects by using the given keys.
 *
 * @param {object[]} data - The array of objects to group
 * @param {string[]} keysArray - The keysArray to use for grouping
 * @returns {object} An object where the key is the grouped key and the value is an array of the grouped objects
 */
export const groupObjectUsingKeys = ({ data = [], keysArray = [] }) => {
  // Use reduce to create an object where the key is the grouped key and the value is an array of the grouped objects
  return data.reduce((result, item) => {
    // Create the group key by joining the values of the given keysArray
    const groupKey = keysArray.map(key => item[key]).join('-')

    // If the group key does not exist in the result, create it
    if (!result[groupKey]) {
      result[groupKey] = []
    }

    // Add the item to the group
    result[groupKey].push(item)

    // Return the result
    return result
  }, {})
}

/**
 * Compare two arrays of objects to see if they are equal.
 * This function checks if the arrays have the same length and if each object in the array
 * is equal to the corresponding object in the other array.
 * @param {object[]} arr1 The first array of objects to compare.
 * @param {object[]} arr2 The second array of objects to compare.
 * @returns {boolean} true if the arrays are equal, false if they are not.
 */
export const compareArrayOfObject = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false

  return arr1.every(
    (obj, index) => JSON.stringify(obj) === JSON.stringify(arr2[index])
  )
}

/**
 * Returns an array of objects that are in arr1 but not in arr2.
 * The comparison is done by using JSON.stringify() to compare the objects.
 * This function is useful when you need to find the objects that have been updated
 * between two arrays of objects.
 * @param {object[]} arr1 - The first array of objects.
 * @param {object[]} arr2 - The second array of objects.
 * @returns {object[]} An array of objects that are only in arr1.
 */
export const returnOnlyUpdatedItems = (arr1, arr2) => {
  // Use the filter method to return an array of objects that are in arr1 but not in arr2
  // The comparison is done by using JSON.stringify() to compare the objects
  return arr2.filter(
    obj2 =>
      // Use the some method to check if any of the objects in arr2 are equal to the current object in arr1
      // If none of the objects are equal, then the current object is only in arr1
      !arr1.some(obj1 => JSON.stringify(obj1) === JSON.stringify(obj2))
  )
}
