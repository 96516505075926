import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Modal,
  Tabs,
  Text,
  Title
} from '@mantine/core'
import {
  IconAlertCircle,
  IconArrowLeft,
  IconCalendar,
  IconCheck,
  IconExclamationCircle,
  IconX
} from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import DateInputComp from '../../commonComponents/components/dateInput/DateInputComp'
import TextInputComp from '../../commonComponents/components/textInput/TextInputComp'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  editCompletedJourney,
  getAllDataV2,
  getAllDataV3,
  getAllDataV4,
  getAllDataWithFilters,
  hierarchicalInsert
} from '../../services/common.service'
import { getCabinUsers } from '../../services/employees.service'
import Header from '../../commonComponents/layout/Header'
import { useNavigate, useParams } from 'react-router-dom'
import { notifications } from '@mantine/notifications'
import {
  compareObject,
  returnOnlyUpdatedItems
} from '../../utils/objectManuplation.utils'
import InternalOrders from './journeyEditComponents/InternalOrders'
import CustomerOrders from './journeyEditComponents/CustomerOrders'
import JourneyLegs from './journeyEditComponents/JourneyLegs'
import SummaryModal from './journeyEditComponents/SummaryModal'

const JourneyEditForm = () => {
  const navigate = useNavigate()
  const { journeyId } = useParams()
  const [activeLeg, setActiveLeg] = useState({ position: 1 })
  const [legData, setLegData] = useState([{}])
  const [originalLegData, setOriginalLegData] = useState([{}])
  const [orderErrorObj, setOrderErrorObj] = useState({
    internal: [],
    customer: []
  })
  const [customerOrders, setCustomerOrders] = useState([])
  const [internalOrders, setInternalOrders] = useState([])
  const [originalOrders, setOriginalOrders] = useState({
    internal: [],
    customer: []
  })
  const [saveLoading, setSaveLoading] = useState(false)
  const [summaryModal, setSummaryModal] = useState({ modal: false })
  const [endJourneyModal, setEndJourneyModal] = useState({ modal: false })
  const queryClient = useQueryClient()

  const allProductsQuery = useQuery({
    queryKey: ['all-products'],
    queryFn: () =>
      getAllDataV3({
        source: 'products',
        filter: { is_active: true },
        sort: { id: 'product_code', desc: false },
        page: -1
      }),
    select: data => {
      return data?.data?.map(item => ({
        ...item,
        label: item?.product_name,
        value: item?.id?.toString()
      }))
    }
  })

  const legsQuery = useQuery({
    queryKey: ['all-legs', journeyId],
    queryFn: () =>
      getAllDataV3({
        source: 'journey_leg',
        page: -1,
        filter: { journey_id: journeyId },
        sort: [{ id: 'leg_number', desc: false }]
      })
  })

  const legProductsQuery = useQuery({
    queryKey: ['leg-products', activeLeg?.id],
    queryFn: () =>
      getAllDataV3({
        source: 'journey_leg_inventory',
        page: -1,
        filter: { journey_leg_id: activeLeg?.id }
      }),
    enabled: Boolean(activeLeg?.id)
  })

  const getJourneyDetails = useQuery({
    queryKey: ['get-journey-details'],
    queryFn: () =>
      getAllDataV4({ source: `items/journey/${journeyId}`, page: -1 }),
    select: data => {
      return data?.data?.[0]
    }
  })

  const allPaymentExceptionReasonsQuery = useQuery({
    queryKey: ['all-payment_exception_reasons'],
    queryFn: () =>
      getAllDataWithFilters({
        source: 'payment_exception_reasons',
        filter: [{ key: 'is_active', value: true }],
        sort: { id: 'reason', desc: false },
        page: -1
      }),
    select: data => {
      return data?.data?.map(item => ({
        ...item,
        label: item?.reason,
        value: item?.reason
      }))
    }
  })

  const aircraftQuery = useQuery({
    queryKey: ['all-aircrafts'],
    queryFn: () =>
      getAllDataV3({
        source: 'aircrafts',
        page: -1,
        filter: { is_active: true }
      }),
    select: data => {
      return data?.data?.map(items => {
        return {
          label: items?.tail_reg_no,
          value: `${items?.id}`
        }
      })
    }
  })

  const usersQuery = useQuery({
    queryKey: ['all-cabin-users'],
    queryFn: () => getCabinUsers({ page: -1 }),
    select: data => {
      return data?.data?.map(items => {
        return {
          ...items,
          label: `${items?.emp_name} (${items?.emp_code})`,
          value: `${items?.id}`
        }
      })
    }
  })

  const getOrderDetailsQuery = useQuery({
    queryKey: ['get-journey-order-details', activeLeg?.id],
    queryFn: () =>
      getAllDataV4({
        source: `items/journey_order_item?filters={"journey_order_id.journey_leg_id_eq":${activeLeg?.id}}&page=-1&sort_by=id`
      }),
    enabled: Boolean(activeLeg?.id)
  })

  const allOrdersCountOfJourneyQuery = useQuery({
    queryKey: ['get-journey-order-details-count', journeyId],
    queryFn: () =>
      getAllDataV4({
        source: `items/journey_order?filters={"journey_id_eq":${journeyId}}&fields=journey_leg_id&group_by=["journey_leg_id"]&aggregate={"count":"journey_id"}`
      }),
    enabled: Boolean(journeyId)
  })

  const getOrderSummaryQuery = useQuery({
    queryKey: ['get-order-summary', activeLeg?.id],
    queryFn: () =>
      getAllDataV2({
        source: `sales_summary`,
        filter: [{ key: 'journey_leg_id', value: activeLeg?.id }],
        page: -1
      }),
    enabled: Boolean(activeLeg?.id && Boolean(summaryModal?.modal))
  })

  const downloadOrdersQuery = useMutation({
    mutationFn: () =>
      getAllDataV2({
        source: `sales_summary`,
        filter: [{ key: 'journey_leg_id', value: activeLeg?.id }],
        page: -1,
        download: true
      }),
    onSuccess: data => {
      if (data?.data?.[0]?.url) {
        window.open(data?.data?.[0]?.url, '_blank')
      }
    },
    onError: e => {
      console.log(e)

      notifications.show({
        title: 'Error',
        message: e?.message || 'Something went wrong',
        autoClose: 3000,
        color: 'red',
        icon: <IconX />
      })
    }
  })

  const saveJourneyMutation = useMutation({
    mutationFn: body =>
      hierarchicalInsert({
        source: ['journey_order_item', 'journey_order'],
        body
      }),
    onSuccess: () => {
      notifications.update({
        id: journeyId,
        title: 'Order Details Updated',
        message: 'Please wait till we update Orders',
        color: 'green',
        loading: false,
        autoClose: 3000,
        icon: <IconCheck />
      })
      queryClient.invalidateQueries([
        'all-journeys',
        'all-legs',
        'leg-products',
        'get-journey-details',
        'get-journey-order-details'
      ])
    },
    onError: e => {
      console.log(e)
      notifications.update({
        id: journeyId,
        title: 'Sorry!',
        message: e || 'Something went wrong',
        color: 'red',
        loading: false,
        autoClose: 3000,
        icon: <IconExclamationCircle />
      })
    }
  })

  const saveCompletedJourneyMutation = useMutation({
    mutationFn: body =>
      editCompletedJourney({
        id: activeLeg?.id,
        body
      }),
    onSuccess: () => {
      notifications.update({
        id: journeyId,
        title: 'Order Details Updated',
        message: 'Please wait till we update Orders',
        color: 'green',
        loading: false,
        autoClose: 3000,
        icon: <IconCheck />
      })
      queryClient.invalidateQueries([
        'all-journeys',
        'all-legs',
        'leg-products',
        'get-journey-details',
        'get-journey-order-details'
      ])
    },
    onError: e => {
      console.log(e)
      notifications.update({
        id: journeyId,
        title: 'Sorry!',
        message: e || 'Something went wrong',
        color: 'red',
        loading: false,
        autoClose: 3000,
        icon: <IconExclamationCircle />
      })
    }
  })

  useEffect(() => {
    if (getOrderDetailsQuery?.data?.data) {
      const customerOrderDetailsArray = getOrderDetailsQuery.data.data
        .filter(item => !item?.journey_order_id?.internal_sales)
        ?.map(item => ({
          ...item,
          journey_order_id: {
            ...item?.journey_order_id,
            sales_type: item?.payment_exception_reasons ? 'FOC' : 'Sales'
          }
        }))
      setCustomerOrders([
        ...customerOrderDetailsArray
        // eslint-disable-next-line no-unsafe-optional-chaining
      ])
      const internalOrderDetailsArray = getOrderDetailsQuery.data.data
        .filter(item => item?.journey_order_id?.internal_sales)
        ?.map(item => ({
          ...item,
          journey_order_id: {
            ...item?.journey_order_id,
            sales_type: item?.journey_order_id?.internal_user_id
              ? usersQuery?.data?.find(
                  user => item?.journey_order_id?.internal_user_id == user.id
                )?.emp_category_id?.category_name == 'Cabin'
                ? 'Cabin'
                : 'Cockpit'
              : null
          }
        }))
      setInternalOrders([
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...internalOrderDetailsArray
      ])
      setOriginalOrders({
        internal: [...internalOrderDetailsArray],
        customer: [...customerOrderDetailsArray]
      })
    }
  }, [getOrderDetailsQuery?.data?.data])

  const checkErrorAndSave = async () => {
    const buildResult = async ({ prev_leg_id }) => {
      const prevLegInventory = await getAllDataV3({
        source: 'journey_leg_inventory',
        page: -1,
        filter: { journey_leg_id: prev_leg_id }
      })

      return legProductsQuery?.data?.data?.map(item => {
        const arrayOfCurrProduct = [
          ...customerOrders,
          ...internalOrders
        ].filter(inv => inv?.product_id?.id == item?.product_id?.id)
        const prevLeg = prevLegInventory?.data?.find(
          i => i?.product_id?.id === item?.product_id?.id
        )
        console.log(arrayOfCurrProduct)

        const current_cc =
          arrayOfCurrProduct
            ?.filter(
              item => item?.journey_order_id?.sold_by?.toLowerCase() === 'cc'
            )
            .reduce((acc, curr) => acc + curr?.quantity, 0) || 0

        const current_scc =
          arrayOfCurrProduct
            ?.filter(
              item => item?.journey_order_id?.sold_by?.toLowerCase() === 'scc'
            )
            .reduce((acc, curr) => acc + curr?.quantity, 0) || 0

        const prev_opening_cc =
          activeLeg?.position === 1
            ? prevLeg?.closing_cc + item?.opening_cc || 0
            : prevLeg?.closing_cc || item?.opening_cc || 0
        const prev_opening_scc =
          activeLeg?.position === 1
            ? prevLeg?.closing_scc + item?.opening_scc || 0
            : prevLeg?.closing_scc || item?.opening_scc || 0
        console.log('prev_opening_cc', current_cc)
        console.log('prev_opening_scc', current_scc)

        return {
          ...item,
          opening_cc: prev_opening_cc,
          opening_scc: prev_opening_scc,
          closing_cc:
            current_cc > 0
              ? (prev_opening_cc || 0) - current_cc
              : prev_opening_cc,
          closing_scc:
            current_scc > 0
              ? (prev_opening_scc || 0) - current_scc
              : prev_opening_scc
        }
      })
    }

    if (
      !compareObject(
        legData?.[activeLeg?.position - 1],
        originalLegData?.[activeLeg?.position - 1]
      )
    ) {
      notifications.show({
        title: 'Warning',
        message:
          'There are some leg changes not saved. Please save it or reset it.',
        color: 'red',
        icon: <IconAlertCircle />
      })
      return
    }
    let isError = false
    let resultError = { customer: [], internal: [] }
    internalOrders.forEach(item => {
      let error = {}
      if (!item?.journey_order_id?.sold_by) {
        error.sold_by = 'Please select sold by'
        isError = true
      }
      if (!item?.journey_order_id?.internal_user_id) {
        error.internal_user_id = 'Please select seat'
        isError = true
      }
      if (!item?.payment_exception_reasons) {
        error.payment_exception_reasons = 'Please select reason'
        isError = true
      }
      if (!item?.journey_order_id?.sales_type) {
        error.sales_type = 'Please select Type'
        isError = true
      }
      if (!item?.product_id?.id) {
        error.product_id = 'Please select product'
        isError = true
      }
      if (!item?.quantity) {
        error.quantity = 'Please enter quantity'
        isError = true
      }
      // if (isError) {
      resultError.internal.push(error)
      // }
    })

    customerOrders.forEach(item => {
      let error = {}
      if (!item?.journey_order_id?.sold_by) {
        error.sold_by = 'Please select sold by'
        isError = true
      }
      if (!item?.journey_order_id?.seat) {
        error.seat = 'Please select seat'
        isError = true
      }
      if (
        item?.journey_order_id?.sales_type === 'FOC' &&
        !item?.payment_exception_reasons
      ) {
        error.payment_exception_reasons = 'Please select reason'
        isError = true
      }
      if (!item?.journey_order_id?.sales_type) {
        error.sales_type = 'Please select Type'
        isError = true
      }
      if (!item?.product_id?.id) {
        error.product_id = 'Please select product'
        isError = true
      }
      if (!item?.quantity) {
        error.quantity = 'Please enter quantity'
        isError = true
      }
      // if (isError) {
      resultError.customer.push(error)
      // }
    })
    setOrderErrorObj({ ...resultError })

    if (isError) {
      setSaveLoading(false)
      notifications.show({
        title: 'Error',
        message: 'Please fill all the mandatory fields',
        color: 'red',
        icon: <IconX />
      })
    }

    if (!isError) {
      notifications.show({
        id: journeyId,
        title: 'Updating Orders...',
        message: 'Please do not close anything.',
        loading: true,
        autoClose: false
      })
      let legInventory = []
      let result = [
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...internalOrders?.map(item => ({
          ...item,
          sold_by: item?.journey_order_id?.sold_by,
          seat: item?.journey_order_id?.seat,
          sales_type: item?.journey_order_id?.sales_type,
          internal_sales: item?.journey_order_id?.internal_sales
        })),
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...customerOrders?.map(item => ({
          ...item,
          sold_by: item?.journey_order_id?.sold_by,
          seat: item?.journey_order_id?.seat,
          sales_type: item?.journey_order_id?.sales_type,
          internal_sales: item?.journey_order_id?.internal_sales
        }))
      ]
      let body = [
        ...result.map(value => ({
          journey_order: {
            ...value?.journey_order_id,
            unique_order_uuid:
              value?.journey_order_id?.unique_order_uuid || crypto.randomUUID(),
            journey_id: Number(journeyId),
            journey_leg_id: activeLeg?.id,
            internal_sales: value?.internal_sales || false,
            payment_exception_reasons: value?.payment_exception_reasons,
            price: Number(value?.quantity) * Number(value?.unit_price),
            quantity: value?.quantity,
            journey_order_item: [
              {
                id: value?.id,
                journey_id: journeyId,
                journey_leg_id: activeLeg?.id,
                product_id: value?.product_id?.id,
                journey_order_id: value?.journey_order_id?.id,
                product_name: value?.product_id.product_name,
                price: Number(value?.quantity) * Number(value?.unit_price),
                product_category:
                  value?.product_id.category_id?.id ||
                  value?.product_id.category_id,
                product_code: value?.product_id.product_code,
                payment_exception_reasons: value?.payment_exception_reasons,
                quantity: value?.quantity,
                unit_price: value?.product_id.selling_price,
                created_by: value?.created_by?.id,
                updated_by: value?.updated_by?.id
              }
            ]
          }
        }))
      ]

      if (getJourneyDetails?.data?.journey_status !== 'completed') {
        let prev_leg_id
        if (activeLeg?.position === 1) {
          prev_leg_id = await getAllDataV3({
            // eslint-disable-next-line no-unsafe-optional-chaining
            page: 1,
            source: 'journey_leg',
            sort: [
              { id: 'journey_date', desc: true },
              { id: 'departure_time', desc: true }
            ],
            filter: {
              retrieved: false,
              is_last_leg: true,
              completed_by_scc: true,
              completed_by_cc: true,
              aircraft_id: getJourneyDetails?.data?.aircraft_id?.id
            }
          }).then(res => {
            if (res?.data?.[0]?.id) {
              return res?.data?.[0]?.id
            }
          })
        } else {
          prev_leg_id = legData?.[activeLeg?.position - 2]?.id
        }
        if (prev_leg_id) {
          legInventory = await buildResult({ prev_leg_id })
        } else {
          legInventory = legProductsQuery?.data?.data?.map(item => {
            const arrayOfCurrProduct = [
              ...customerOrders,
              ...internalOrders
            ].filter(inv => inv?.product_id?.id == item?.product_id?.id)
            const current_cc =
              arrayOfCurrProduct
                ?.filter(
                  item =>
                    item?.journey_order_id?.sold_by?.toLowerCase() === 'cc'
                )
                .reduce((acc, curr) => acc + curr?.quantity, 0) || 0

            const current_scc =
              arrayOfCurrProduct
                ?.filter(
                  item =>
                    item?.journey_order_id?.sold_by?.toLowerCase() === 'scc'
                )
                .reduce((acc, curr) => acc + curr?.quantity, 0) || 0
            return {
              ...item,
              closing_cc: (item?.opening_cc || 0) - current_cc,
              closing_scc: (item?.opening_scc || 0) - current_scc
            }
          })
        }
        body = [
          ...body,
          {
            journey_leg: {
              ...activeLeg,
              completed_by_cc: true,
              completed_by_scc: true
            }
          },
          ...legInventory.map(item => ({
            journey_leg_inventory: {
              ...item,
              journey_leg_id: item?.journey_leg_id?.id,
              product_id: item?.product_id?.id,
              created_by: item?.created_by?.id,
              updated_by: item?.updated_by?.id
            }
          }))
        ]

        saveJourneyMutation.mutate(body, {
          onSettled: () => {
            setSaveLoading(false)
            setEndJourneyModal({})
          }
        })
      } else {
        if (
          legsQuery?.data?.data?.length &&
          !legsQuery?.data?.data?.find(i => i?.is_last_leg)?.retrieved
        ) {
          const prev_leg_id = legData?.[activeLeg?.position - 2]?.id
          if (prev_leg_id) {
            legInventory = await buildResult({ prev_leg_id })
          } else {
            legInventory = legProductsQuery?.data?.data?.map(item => {
              const arrayOfCurrProduct = [
                ...customerOrders,
                ...internalOrders
              ].filter(inv => inv?.product_id?.id == item?.product_id?.id)
              const current_cc =
                arrayOfCurrProduct
                  ?.filter(
                    item =>
                      item?.journey_order_id?.sold_by?.toLowerCase() === 'cc'
                  )
                  .reduce((acc, curr) => acc + curr?.quantity, 0) || 0

              const current_scc =
                arrayOfCurrProduct
                  ?.filter(
                    item =>
                      item?.journey_order_id?.sold_by?.toLowerCase() === 'scc'
                  )
                  .reduce((acc, curr) => acc + curr?.quantity, 0) || 0
              return {
                ...item,
                closing_cc: (item?.opening_cc || 0) - current_cc,
                closing_scc: (item?.opening_scc || 0) - current_scc
              }
            })
          }

          body = [
            ...body,
            {
              journey_leg: {
                ...activeLeg,
                completed_by_cc: true,
                completed_by_scc: true
              }
            },
            ...legInventory.map(item => ({
              journey_leg_inventory: {
                ...item,
                journey_leg_id: item?.journey_leg_id?.id,
                product_id: item?.product_id?.id,
                created_by: item?.created_by?.id,
                updated_by: item?.updated_by?.id
              }
            }))
          ]

          saveJourneyMutation.mutate(body, {
            onSettled: () => {
              setSaveLoading(false)
              setEndJourneyModal({})
            }
          })
        } else {
          let completedBody = returnOnlyUpdatedItems(
            [...originalOrders.customer, ...originalOrders.internal],
            [...customerOrders, ...internalOrders]
          ).map(value => ({
            journey_order: {
              ...value?.journey_order_id,
              unique_order_uuid:
                value?.journey_order_id?.unique_order_uuid ||
                crypto.randomUUID(),
              journey_id: Number(journeyId),
              journey_leg_id: activeLeg?.id,
              internal_sales: value?.internal_sales || false,
              payment_exception_reasons: value?.payment_exception_reasons,
              price: Number(value?.quantity) * Number(value?.unit_price),
              quantity: value?.quantity,
              journey_order_item: [
                {
                  id: value?.id,
                  journey_id: journeyId,
                  journey_leg_id: activeLeg?.id,
                  product_id: value?.product_id?.id,
                  journey_order_id: value?.journey_order_id?.id,
                  product_name: value?.product_id.product_name,
                  price: Number(value?.quantity) * Number(value?.unit_price),
                  product_category:
                    value?.product_id.category_id?.id ||
                    value?.product_id.category_id,
                  product_code: value?.product_id.product_code,
                  payment_exception_reasons: value?.payment_exception_reasons,
                  quantity: value?.quantity,
                  unit_price: value?.product_id.selling_price,
                  created_by: value?.created_by?.id,
                  updated_by: value?.updated_by?.id
                }
              ]
            }
          }))
          console.log('completedBody', completedBody)
          if (completedBody?.length) {
            saveCompletedJourneyMutation.mutate(body, {
              onSettled: () => {
                setSaveLoading(false)
                setEndJourneyModal({})
              }
            })
          } else {
            notifications.update({
              id: journeyId,
              title: 'No Updates',
              message: 'Please edit or add items to save',
              loading: false,
              autoClose: 300
            })
            setSaveLoading(false)
          }
        }
      }
    }
  }

  return (
    <Box>
      <Group gap={0}>
        <ActionIcon variant='transparent' onClick={() => navigate('/journeys')}>
          <IconArrowLeft size={20} color='gray' />
        </ActionIcon>
        <Header title={'Sales Entry'} />
      </Group>

      <Flex direction={'column'} gap={4} mt={'md'}>
        <Box flex={1}>
          <Grid>
            <Grid.Col span={3}>
              <DateInputComp
                isRequired
                label={'Journey Date'}
                value={
                  getJourneyDetails?.data?.journey_date
                    ? new Date(getJourneyDetails?.data?.journey_date)
                    : null
                }
                rightSection={<IconCalendar size={16} />}
                placeholder={'Select Journey Date'}
                disabled
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <TextInputComp
                isRequired
                label={'Sector'}
                placeholder={'Sector'}
                value={getJourneyDetails?.data?.sector_pattern}
                disabled
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <JourneyLegs
                setActiveLeg={setActiveLeg}
                activeLeg={activeLeg}
                legData={legData}
                setLegData={setLegData}
                legsQuery={legsQuery}
                usersQuery={usersQuery}
                setOriginalLegData={setOriginalLegData}
                originalLegData={originalLegData}
                aircraftQuery={aircraftQuery}
                journeysOrderCount={allOrdersCountOfJourneyQuery?.data?.data}
                isAddNew={
                  getJourneyDetails?.data?.journey_status !== 'completed'
                }
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Tabs defaultValue={'customer_sales'}>
                <Tabs.List style={{ position: 'relative' }}>
                  <Tabs.Tab value='customer_sales'>Customer Sales</Tabs.Tab>
                  <Tabs.Tab value='internal'>{`Internal (cabin/cockpit)`}</Tabs.Tab>
                  <Box style={{ position: 'absolute', right: 0 }}>
                    <Button
                      variant='transparent'
                      size='xs'
                      onClick={() => setSummaryModal({ modal: true })}
                    >
                      View Summary
                    </Button>
                  </Box>
                </Tabs.List>
                <Tabs.Panel value='customer_sales'>
                  <CustomerOrders
                    allProducts={allProductsQuery}
                    allPaymentReason={allPaymentExceptionReasonsQuery}
                    activeLeg={activeLeg}
                    usersQuery={usersQuery}
                    // checkErrorAndSave={checkErrorAndSave}
                    isReadOnly={
                      !compareObject(
                        legData?.[activeLeg?.position - 1],
                        originalLegData?.[activeLeg?.position - 1]
                      )
                    }
                    addNewCustomerSales={() =>
                      setCustomerOrders([
                        ...customerOrders,
                        {
                          journey_id: journeyId,
                          journey_leg_id: activeLeg?.id,
                          journey_order_id: {
                            internal_sales: false,
                            purchase_date: activeLeg?.journey_date
                          }
                        }
                      ])
                    }
                    customerSalesData={[...customerOrders]}
                    setCustomerSalesData={setCustomerOrders}
                    errorsObj={orderErrorObj.customer}
                    getCustomerSalesDetailsQuery={getOrderDetailsQuery}
                  />
                </Tabs.Panel>
                <Tabs.Panel value='internal'>
                  <InternalOrders
                    allProducts={allProductsQuery}
                    allPaymentReason={allPaymentExceptionReasonsQuery}
                    usersQuery={usersQuery}
                    // checkErrorAndSave={checkErrorAndSave}
                    internalSalesData={internalOrders}
                    setInternalSalesData={setInternalOrders}
                    errorsObj={orderErrorObj.internal}
                    getInternalSalesDetails={getOrderDetailsQuery}
                    addNewInternalSales={() =>
                      setInternalOrders([
                        ...internalOrders,
                        {
                          journey_id: journeyId,
                          journey_leg_id: activeLeg?.id,
                          journey_order_id: {
                            internal_sales: true,
                            seat: '',
                            purchase_date: activeLeg?.journey_date
                          }
                        }
                      ])
                    }
                    activeLeg={activeLeg}
                    isReadOnly={
                      !compareObject(
                        legData?.[activeLeg?.position - 1],
                        originalLegData?.[activeLeg?.position - 1]
                      )
                    }
                  />
                </Tabs.Panel>
              </Tabs>
              <Group justify='flex-end' mt={'md'}>
                <Button
                  size='xs'
                  variant='outline'
                  color='gray'
                  onClick={() => {
                    setCustomerOrders([
                      ...getOrderDetailsQuery.data.data
                        .filter(item => !item?.journey_order_id?.internal_sales)
                        .map(item => ({
                          ...item,
                          journey_order_id: {
                            ...item?.journey_order_id,
                            sales_type: item?.payment_exception_reasons
                              ? 'FOC'
                              : 'Sales'
                          }
                        }))
                    ])
                    setInternalOrders([
                      // eslint-disable-next-line no-unsafe-optional-chaining
                      ...getOrderDetailsQuery.data.data
                        .filter(item => item?.journey_order_id?.internal_sales)
                        ?.map(item => ({
                          ...item,
                          journey_order_id: {
                            ...item?.journey_order_id,
                            sales_type: item?.journey_order_id?.internal_user_id
                              ? usersQuery?.data?.find(
                                  user =>
                                    item?.journey_order_id?.internal_user_id ==
                                    user.id
                                )?.emp_category_id?.category_name == 'Cabin'
                                ? 'Cabin'
                                : 'Cockpit'
                              : null
                          }
                        }))
                    ])
                  }}
                >
                  Reset
                </Button>
                <Button
                  size='xs'
                  color='teal'
                  onClick={async () => {
                    if (
                      activeLeg?.is_last_leg &&
                      activeLeg?.journey_status !== 'Completed'
                    ) {
                      setEndJourneyModal({ modal: true })
                    } else {
                      setSaveLoading(true)
                      checkErrorAndSave()
                    }
                  }}
                  loading={saveLoading}
                >
                  {activeLeg?.is_last_leg &&
                  activeLeg?.journey_status !== 'Completed'
                    ? 'End Journey'
                    : 'Save'}
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </Box>
      </Flex>
      <Modal
        opened={endJourneyModal?.modal}
        onClose={() => setEndJourneyModal({ modal: false })}
        title={<Title size={'md'}>End Journey?</Title>}
      >
        <Text>Once confirmed the journey will be marked as completed.</Text>
        <Group justify='flex-end' mt={'md'}>
          <Button
            color={'gray'}
            variant='light'
            size='xs'
            onClick={() => setEndJourneyModal({ modal: false })}
          >
            Cancel
          </Button>
          <Button
            color={'teal'}
            size='xs'
            loading={saveLoading}
            onClick={() => {
              setSaveLoading(true)
              checkErrorAndSave()
            }}
          >
            Confirm
          </Button>
        </Group>
      </Modal>
      <SummaryModal
        opened={summaryModal?.modal}
        onDownload={downloadOrdersQuery}
        downloadLoading={downloadOrdersQuery?.isPending}
        onClose={setSummaryModal}
        data={getOrderSummaryQuery}
      />
    </Box>
  )
}

export default JourneyEditForm
