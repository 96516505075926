import { Button, Drawer, Group, Title } from '@mantine/core'
import { createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import ReactTable from '../../../commonComponents/reactTable/ReactTable'
import { IconDownload } from '@tabler/icons-react'

// eslint-disable-next-line react/display-name
const SummaryModal = React.memo(
  ({ opened, onClose, data, onDownload, downloadLoading }) => {
    const columnHelper = createColumnHelper()
    const column = [
      columnHelper.accessor('journey_date', {
        header: 'Journey Date'
      }),
      columnHelper.accessor('route', {
        header: 'Route'
      }),
      columnHelper.accessor('scc_or_cc', {
        header: 'SCC/CC'
      }),
      columnHelper.accessor('reason_for_foc', {
        header: 'Reason for FOC',
        cell: value => value.row.original.reason_for_foc || '-'
      }),
      columnHelper.accessor('consumed_by', {
        header: 'Consumed by'
      }),
      columnHelper.accessor('product', {
        header: 'Product'
      }),
      columnHelper.accessor('qty', {
        header: 'Quantity'
      }),
      columnHelper.accessor('price', {
        header: 'Selling Price',
        cell: ({ row }) =>
          row.original?.price ? `₹ ${row.original?.price}` : '₹ 0'
      }),
      columnHelper.accessor('foc_value', {
        header: 'FOC Value',
        cell: ({ row }) =>
          row.original?.foc_value ? `₹ ${row.original?.foc_value}` : '₹ 0'
      }),
      columnHelper.accessor('sales_value', {
        header: 'Sales Value',
        cell: ({ row }) =>
          row.original?.sales_value ? `₹ ${row.original?.sales_value}` : '₹ 0'
      })
    ]
    return (
      <Drawer
        opened={opened}
        onClose={onClose}
        title={
          <Group justify='space-between'>
            <Title size={'md'} fw={600}>
              Summary View
            </Title>
            <Button
              size='xs'
              variant='outline'
              leftSection={<IconDownload size={16} />}
              loading={downloadLoading}
              onClick={() => {
                onDownload.mutate()
              }}
            >
              Download
            </Button>
          </Group>
        }
        padding='md'
        size={'70%'}
        position='right'
        radius='md'
        offset={8}
        // withCloseButton={false}
        overlayProps={{ backgroundOpacity: 0.5, blur: 1 }}
      >
        <ReactTable
          rowData={data?.data?.data}
          columnData={column}
          useApiPagination={false}
          loading={data?.isLoading}
        />
      </Drawer>
    )
  }
)

export default SummaryModal
