import { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import { displayNotification } from '../../commonComponents/notifications/displayNotification'

import Body from '../../commonComponents/body/Body'
import ReactTable from '../../commonComponents/reactTable/ReactTable'
import { generateReportHeaders } from '../../utils/table.utils'
import { fileUploadAPI, getAllData, getHeaders, updateRecord } from '../../services/common.service'
import ActionButton from '../../commonComponents/components/actionButton/ActionButton'
import EditModal from '../../commonComponents/components/editModal/EditModal'
import { createNewUser } from '../../services/employees.service'
import { ActionIcon, Button, Grid, Group, ScrollArea } from '@mantine/core'
import { IconCheck, IconPlus, IconRefresh } from '@tabler/icons-react'
import ModalComp from '../../commonComponents/components/modal/ModalComp'
import { FormBuilderFields } from '../../commonComponents/formBuilder/FormBuilderFields'
import TextInputComp from '../../commonComponents/components/textInput/TextInputComp'
import { generatePassword } from '../../utils/common.utils'
import { getFormBuilderData } from '../../services/master.service'

const source = 'users';
let defaultSorting = { id: 'emp_name', desc: false };
const Employees = () => {
  const [pagination, setPagination] = useState({ page: 1, search: '', source, sort: [defaultSorting] })
  const [editModal, setEditModal] = useState()
  const [formBuilder, setFormBuilder] = useState({})
  const [checkError, setCheckError] = useState()

  const allRecordsQuery = useQuery({
    queryKey: ['all-records', source, pagination],
    queryFn: () => getAllData(pagination)
  })

  const tableHeadersQuery = useQuery({
    queryKey: ['table-headers', source],
    queryFn: () => getHeaders({ source }),
    select: data => {
      return { headers: generateReportHeaders(data?.[0]?.filter(item => item?.field !== 'plain_password')), data: data?.[0] }
    }
  })

  const getDataHeaderQuery = useQuery({
    queryKey: ['formBuilders-fields', source],
    queryFn: () => getFormBuilderData({ tableName: source }),
    select: data => {
      return data?.[0]
    }
  })

  const uploadDataQuery = useMutation({
    mutationFn: body => fileUploadAPI({ body, tableName: source })
  })

  const createDataQuery = useMutation({
    mutationFn: body => createNewUser({ body })
  })

  const updateDataQuery = useMutation({
    mutationFn: body => updateRecord({ body, source })
  })

  useEffect(() => {
    if (pagination?.search) {
      setPagination(old => ({ ...old, page: 1 }))
    }
  }, [pagination?.search])

  const handleSorting = e => {
    // find the sorting data in the array from previous state
    const d = e(pagination.sort);
    setPagination(old => ({ ...old, sort: d }));
  }

  const handleDataSave = value => {
    let check = false
    getDataHeaderQuery?.data
      ?.filter(item => !item?.hidden && item?.field !== 'plain_password')
      ?.forEach(item => {
        const originalRegexString = item?.special
        const desiredRegexString = item?.special
          ? new RegExp(originalRegexString.replace(/\\\\/g, '\\'))
          : null
        if (
          (item?.is_required &&
            [null, undefined, ''].includes(value?.[item?.field])) ||
          (item?.special &&
            value?.[item?.field] &&
            !desiredRegexString.test(value?.[item?.field]))
        ) {
          check = true
          setCheckError(old => ({
            ...old,
            [item?.field]: item?.special
              ? 'Please Enter Valid Value'
              : 'Please Enter Value'
          }))
        }
      })
    if (!check) {
      setCheckError({})
      createDataQuery?.mutate(value, {
        onSuccess: () => {
          displayNotification({
            message: 'New Employee Added Successfully',
            variant: 'success'
          })
          setFormBuilder({ modal: false });
          allRecordsQuery?.refetch()
        },
        onError: err => {
          displayNotification({
            message: err?.message || err,
            variant: 'error'
          })
        }
      })
    }
  }

  const handleAddNew = () => {
      setFormBuilder({
      modal: true,
      data: {
        is_active: true,
        user_password: generatePassword()
      }
    })
  }

  return (
    <Body>
      <ActionButton
        title={'Employees'}
        pagination={pagination}
        setPagination={setPagination}
        actionName={source}
        tableName={source}
        uploadDataQuery={uploadDataQuery}
        queryKey={'users-list'}
        addNewComponent={(
          <Button
            size='xs'
            rightSection={<IconPlus size={16} />}
            onClick={handleAddNew}
          >
            Add New
          </Button>
        )}
      />

      <ReactTable
        sorting={pagination.sort}
        setSorting={handleSorting}
        columnData={tableHeadersQuery?.data?.headers}
        loading={
          allRecordsQuery?.isLoading || tableHeadersQuery?.isLoading
        }
        onRowClick={e => setEditModal({ data:e, modal: true})}
        totalNoOfPages={
          allRecordsQuery?.data?.metadata?.total_number_of_page
        }
        totalNoOfRecords={allRecordsQuery?.data?.metadata?.records}
        rowData={allRecordsQuery?.data?.data}
        page={pagination?.page}
        setPage={page => setPagination({ ...pagination, page })}
      />

      <EditModal
        formData={editModal?.data}
        opened={editModal?.modal}
        onClose={() => setEditModal({ modal: false })}
        builderTitle={'Edit Employee Details'}
        saveLoading={updateDataQuery?.isPending}
        tableName={source}
        onSave={(e) =>
          updateDataQuery?.mutate(e, {
            onSuccess: () => {
              displayNotification({
                message: 'Employee details Updated Successfully',
                variant: 'success'
              })
              setEditModal({ modal: false, data: {} })
              allRecordsQuery?.refetch()
            },
            onError: err => {
              displayNotification({
                message: err?.message || err,
                variant: 'error'
              })
            }
          })
        }
      />

      <ModalComp
        size='lg'
        title={'Add New Employee'}
        opened={formBuilder?.modal}
        onClose={() => {
          setFormBuilder({ modal: false })
          setCheckError({})
        }}
      >
        <ScrollArea.Autosize
          mah={'70vh'}
          mih={120}
          offsetScrollbars
        >
          <Grid>
            {
              getDataHeaderQuery?.data
                ?.filter(item => !item?.hidden && item?.field !== 'plain_password')
                ?.map((item, index) => (
                  <Grid.Col key={index} span={6}>
                    <FormBuilderFields
                      item={item}
                      key={index}
                      value={formBuilder?.data}
                      setValue={setFormBuilder}
                      checkError={checkError}
                    />
                  </Grid.Col>
                ))
            }
            <Grid.Col key={'pwd'} span={6}>
              <TextInputComp
                isRequired
                label={'Password'}
                value={formBuilder?.data?.user_password}
                error={checkError?.user_password}
                rightSection={<ActionIcon
                  onClick={() => setFormBuilder({ ...formBuilder, data: { ...formBuilder?.data, user_password: generatePassword() } })}
                  variant={'subtle'}
                  title='Generate Password'
                  color={'primary'}
                  // size={'xs'}
                  // radius={'md'}
                  // compact
                ><IconRefresh size={16} /></ActionIcon>}
                setValue={e => {
                  setFormBuilder(old => {
                    return {
                      ...old,
                      data: {
                        ...old?.data,
                        user_password: e
                      }
                    }
                  })
                }}
              />
            </Grid.Col>
          </Grid>
        </ScrollArea.Autosize>
        <Group justify='flex-end' mt={'md'}>
          <Group>
            <Button
              variant='light'
              color='gray'
              onClick={() => {
                setFormBuilder({ modal: false })
                setCheckError({})
              }}
            >
              Cancel
            </Button>
            <Button
              leftSection={<IconCheck size={16} />}
              onClick={() => handleDataSave(formBuilder.data)}
              loading={createDataQuery.isLoading}
            >
              Save
            </Button>
          </Group>
        </Group>
      </ModalComp>
    </Body>
  )
}

export default Employees
