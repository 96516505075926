import { IconCalendar } from "@tabler/icons-react";
import DateInputComp from "../components/dateInput/DateInputComp";
import MultiSelectComp from "../components/multiSelect/MultiSelectComp";
import { Flex, Text } from "@mantine/core";
import dayjs from "dayjs";
import MultiSelectWithAPI from "../components/multiSelectWithAPI/MultiSelectWithAPI";
import TimeInputComp from "../components/dateInput/TimeInputComp";
import { displayNotification } from "../notifications/displayNotification";

const FilterFields = ({
    value = {},
    setValue,
    item,
    pagination,
    setPagination
}) => {
    switch (item?.interface) {
        case 'DATETIME':
            return (
                <>
                    <Text size='md'>
                        {item?.label}
                    </Text>
                    {item?.type === 'date' ? (
                        <Flex
                            justify='space-between'
                            direction={{
                                base: 'column',
                                md: 'row'
                            }}
                            align='center'
                            gap={'md'}
                        >
                            <DateInputComp
                                label='From'
                                value={value?.data?.[`${item?.field}_gte`] 
                                    ? new Date(value?.data?.[`${item?.field}_gte`]) 
                                    : pagination?.filter?.[item?.field]
                                        ? new Date(pagination?.filter?.[item?.field])
                                        : null}
                                maxDate={value?.data?.[`${item?.field}_lte`]
                                    ? new Date(value?.data?.[`${item?.field}_lte`])
                                    : null}
                                rightSection={!value?.data?.[`${item?.field}_gte`] && <IconCalendar size={16} />}
                                setValue={e => {
                                    console.log(dayjs(e).format('YYYY-MM-DD'))
                                    setValue({
                                        ...value,
                                        data: {
                                            ...value?.data,
                                            [`${item?.field}_gte`]: e ? dayjs(e).format('YYYY-MM-DD') : null
                                        }
                                    })
                                }}
                            />
                            <DateInputComp
                                label='To'
                                value={value?.data?.[`${item?.field}_lte`] 
                                    ? new Date(value?.data?.[`${item?.field}_lte`])
                                    : pagination?.filter?.[item?.field]
                                        ? new Date(pagination?.filter?.[item?.field])
                                        : null}
                                minDate={value?.data?.[`${item?.field}_gte`]
                                    ? new Date(value?.data?.[`${item?.field}_gte`])
                                    : null}
                                rightSection={!value?.data?.[`${item?.field}_lte`] && <IconCalendar size={16} />}
                                setValue={e => {
                                    console.log(dayjs(e).format('YYYY-MM-DD'))
                                    setValue({
                                        ...value,
                                        data: {
                                            ...value?.data,
                                            [`${item?.field}_lte`]: e ? dayjs(e).format('YYYY-MM-DD') : null
                                        }
                                    })
                                }}
                            />
                        </Flex>
                    ) : (
                        <Flex
                            justify='space-between'
                            direction={{
                                base: 'column',
                                md: 'row'
                            }}
                            align='center'
                            gap={'md'}
                        >
                            <TimeInputComp
                                label='From'
                                value={value?.data?.[`${item?.field}_gte`]
                                    ? value?.data?.[`${item?.field}_gte`]
                                    : pagination?.filter?.[item?.field]
                                        ? pagination?.filter?.[item?.field]
                                        : null}
                                maxTime={value?.data?.[`${item?.field}_lte`]
                                    ? value?.data?.[`${item?.field}_lte`]
                                    : null}
                                setValue={e => {
                                    console.log(e?.target?.value)
                                    if (value?.data?.[`${item?.field}_lte`] != null && e?.target?.value <= value?.data?.[`${item?.field}_lte`]) {
                                        setValue({
                                            ...value,
                                            data: {
                                                ...value?.data,
                                                [`${item?.field}_gte`]: e?.target?.value
                                            }
                                        })
                                    } else {
                                        displayNotification({
                                            message: 'Selected time is greater than To time',
                                            variant: 'warning'
                                        })
                                    }
                                }}
                                styles={{
                                    width: '100%'
                                }}
                            />
                            <TimeInputComp
                                styles={{
                                    width: '100%'
                                }}
                                label='To'
                                value={value?.data?.[`${item?.field}_lte`]
                                    ? value?.data?.[`${item?.field}_lte`]
                                    : pagination?.filter?.[item?.field]
                                        ? pagination?.filter?.[item?.field]
                                        : null}
                                minTime={value?.data?.[`${item?.field}_gte`]
                                    ? value?.data?.[`${item?.field}_gte`]
                                    : null}
                                setValue={e => {
                                    console.log(e?.target?.value)
                                    if (value?.data?.[`${item?.field}_gte`] != null && e?.target?.value >= value?.data?.[`${item?.field}_gte`]) {
                                        setValue({
                                            ...value,
                                            data: {
                                                ...value?.data,
                                                [`${item?.field}_lte`]: e?.target?.value
                                            }
                                        })
                                    } else {
                                        displayNotification({
                                            message: 'Selected time is lesser than From time',
                                            variant: 'warning'
                                        })
                                    }
                                }}
                            />
                        </Flex>
                    )}
                </>
            )
        case 'DATE':
            return (
                <>
                    <Text size='md'>
                        {item?.label}
                    </Text>
                    <DateInputComp
                        label='From'
                        value={value?.data?.[`${item?.field}_gte`] 
                            ? new Date(value?.data?.[`${item?.field}_gte`]) 
                            : pagination?.filter?.[item?.field]
                                ? new Date(pagination?.filter?.[item?.field])
                                : null}
                        rightSection={!value?.data?.[`${item?.field}_gte`] && <IconCalendar size={16} />}
                        setValue={e => {
                            console.log(dayjs(e).format('YYYY-MM-DD'))
                            setValue({
                                ...value,
                                data: {
                                    ...value?.data,
                                    [`${item?.field}_gte`]: e ? dayjs(e).format('YYYY-MM-DD') : null
                                }
                            })
                        }}
                    />
                    <DateInputComp
                        label='To'
                        value={value?.data?.[`${item?.field}_lte`] 
                            ? new Date(value?.data?.[`${item?.field}_lte`])
                            : pagination?.filter?.[item?.field]
                                ? new Date(pagination?.filter?.[item?.field])
                                : null}
                        minDate={new Date(value?.data?.[`${item?.field}_gte`])}
                        rightSection={!value?.data?.[`${item?.field}_lte`] && <IconCalendar size={16} />}
                        setValue={e => {
                            console.log(dayjs(e).format('YYYY-MM-DD'))
                            setValue({
                                ...value,
                                data: {
                                    ...value?.data,
                                    [`${item?.field}_lte`]: e ? dayjs(e).format('YYYY-MM-DD') : null
                                }
                            })
                        }}
                    />
                </>
            )
        case 'DROPDOWN':
            return (
                <MultiSelectComp
                    label={item?.label}
                    value={value?.data?.[`${item?.field}_in`] 
                        ? value?.data?.[`${item?.field}_in`]
                        : pagination?.filter?.[item?.field] 
                            ? pagination?.filter?.[item?.field]
                            : []}
                    options={
                        JSON?.parse(item?.options)?.map(val => ({
                            label: val,
                            value: val
                        })) || []
                    }
                    setValue={v => {
                        setValue({
                            ...value,
                            data: {
                                ...value?.data,
                                [`${item?.field}_in`]: v
                            }
                        })
                    }}
                />
            )
        case 'CHECKBOX':
            return (
                <MultiSelectComp
                    label={item?.label}
                    value={value?.data?.[`${item?.field}_in`] 
                        ? value?.data?.[`${item?.field}_in`]
                        : pagination?.filter?.[item?.field] 
                            ? pagination?.filter?.[item?.field]
                            : []}
                    options={[
                        { label: 'Yes', value: 'true' },
                        { label: 'No', value: 'false' }
                    ]}
                    setValue={v => {
                        setValue({
                            ...value,
                            data: {
                                ...value?.data,
                                [`${item?.field}_in`]: v
                            }
                        })
                    }}
                />
            )
        case 'FORM_SEARCH':
            return (
                <MultiSelectWithAPI
                    label={item?.label}
                    value={value?.data?.[`${item?.field}_in`]}
                    tableName={item?.foreign_key_table}
                    itemData={item}
                    placeholder={item?.placeholder}
                    setValue={(v, obj) => {
                        setValue({
                            ...value,
                            data: {
                                ...value?.data,
                                [`${item?.field}_in`]: v
                            }
                        })
                    }}
                />
            )
    }
};

export default FilterFields;