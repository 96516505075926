import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import { displayNotification } from '../../commonComponents/notifications/displayNotification'

import Body from '../../commonComponents/body/Body'
import ReactTable from '../../commonComponents/reactTable/ReactTable'
import { generateReportHeaders } from '../../utils/table.utils'
import { bulkUpsert, createNewRecord, getAllDataV3, getAllDataV2, getHeaders } from '../../services/common.service'
import HeaderWithSearch from '../../commonComponents/components/headersWithSearch/HeaderWithSearch'
import { ActionIcon, Button, Flex, Grid, Group, Indicator, SegmentedControl, Table, Tooltip } from '@mantine/core'
import { IconCheck, IconFilter } from '@tabler/icons-react'
// import { useDisclosure } from '@mantine/hooks'
import ModalComp from '../../commonComponents/components/modal/ModalComp'
import DisplayField from '../../commonComponents/components/DisplayField/DisplayField'
import dayjs from 'dayjs'
import NumberInputComp from '../../commonComponents/components/numberInput/NumberInputComp'
import { COLORS } from '../../constants/colors'
import FilterModal from '../../commonComponents/filter/FilterModal'
import { getFormBuilderData } from '../../services/master.service'

const source = 'retrievals', target='journey_inventory_retrieval', detailSource = 'journey_leg_inventory';
const defaultSorting = [{ id: 'journey_date', desc: true }];
const detailsDefaultSorting = [{ id: 'product_id.product_name', desc: false }];
const defaultFilter = [
  { key: 'products', value: 0, condition: '>' },
  { key: 'status', value: 'pending', condition: '=' },
];
const transformFields = data => {
  return data?.[0]
}

const transformItemDetails = data => {
  // console.log('transformItemDetails >> ', data);
  return data?.map(item => {
    return {
      ...item,
      actual_retrieved: (item?.closing_scc + item?.closing_cc)
    }
  })
}

const Retrievals = () => {
  const [pagination, setPagination] = useState({ page: 1, search: '', source, sort: defaultSorting, filter: defaultFilter })
  const [detailSourceParams, setDetailSourceParams] = useState({ page: -1, source: detailSource, filter: {}, sort: detailsDefaultSorting })
  // const [filterOpened, { toggle: toggleFilter }] = useDisclosure(false)
  const [detailsModal, setDetailsModal] = useState({})
  // const [filter, setFilter] = useState({})
  
  const allRecordsQuery = useQuery({
    queryKey: ['all-records', source, JSON.stringify(pagination)],
    queryFn: () => getAllDataV2(pagination),
  })

  const rowDetailsQuery = useQuery({
    enabled: Boolean(detailSourceParams?.filter?.journey_leg_id),
    queryKey: ['row-details', detailSource, JSON.stringify(detailSourceParams?.filter)],
    queryFn: () => getAllDataV3(detailSourceParams),
    gcTime: 0
  })

  const tableHeadersQuery = useQuery({
    queryKey: ['table-headers', source],
    queryFn: () => getHeaders({ source }),
    select: data => {
      return { headers: generateReportHeaders(data?.[0]), data: data?.[0] }
    }
  })

  const getDataHeaderQuery = useQuery({
    queryKey: ['formBuilders-fields', source],
    queryFn: () => getFormBuilderData({ tableName: source }),
    select: transformFields
  })

  useEffect(() => {
    if (pagination?.search) {
      setPagination(old => ({ ...old, page: 1 }))
    }
  }, [pagination?.search])

  const handleSorting = e => {
    // find the sorting data in the array from previous state
    const d = e(pagination.sort);
    setPagination(old => ({ ...old, sort: d }));
  }

  const handleRowClick = async (row) => {
    console.log('ROW >>', row)
    await setDetailSourceParams(old => ({ ...old, filter: { journey_leg_id: row?.id } }))
    // setTimeout(() => {
      setDetailsModal({
        opened: true,
        data: row
      })
      // rowDetailsQuery.refetch()
    // }, 1000)
  }

  const onSubmitCallback = ({ success }) => {
    if (success) {
      setDetailsModal({});
      allRecordsQuery?.refetch();
      displayNotification({
        message: 'Retrieved Successfully',
        variant: 'success'
      });
    } else {
      displayNotification({
        message: 'Unable to retrieve. Try again later',
        variant: 'error',
      })
    }
  }

  return (
    <Body>
      <Group justify='space-between'>
        <HeaderWithSearch
          title={'Retrievals'}
          pagination={pagination}
          setPagination={setPagination}
        />
        <Group gap={14}>
          <SegmentedControl
            value={pagination?.filter?.[1]?.value}
            onChange={value => {
              setPagination(old => ({ ...old, filter: [defaultFilter[0], { key: 'status', value, condition: '=' }] }));
            }}
            data={[
              { label: 'Pending', value: 'pending' },
              { label: 'Completed', value: 'done' }
            ]}
          />
          {/* <Tooltip
            withArrow
            position='top'
            label={'Open Filters'}
          >
            <Indicator inline disabled={(Object.entries(filter?.data || {})?.length <= defaultFilter?.length)} color="red" size={12}>
              <ActionIcon
                variant='outline'
                color={COLORS.green}
                size={'30'}
                onClick={() => setFilter({ modal: true })}
              >
                <IconFilter strokeWidth={1.5} />
              </ActionIcon>
            </Indicator>
          </Tooltip> */}
        </Group>
      </Group>
      <ReactTable
        sorting={pagination.sort}
        setSorting={handleSorting}
        columnData={tableHeadersQuery?.data?.headers}
        loading={
          allRecordsQuery?.isLoading || tableHeadersQuery?.isLoading
        }
        onRowClick={handleRowClick}
        totalNoOfPages={
          allRecordsQuery?.data?.metadata?.total_number_of_page
        }
        totalNoOfRecords={allRecordsQuery?.data?.metadata?.records}
        rowData={allRecordsQuery?.data?.data}
        page={pagination?.page}
        setPage={page => setPagination({ ...pagination, page })}
      />

      <ModalComp
        opened={detailsModal.opened}
        onClose={() => setDetailsModal({ opened: false, data: {} })}
        title={'Retrieve Items'}
        size={'lg'}
      >
        <RetrievalModal
          journeyDetails={detailsModal?.data}
          itemsList={rowDetailsQuery?.data?.data}
          callback={onSubmitCallback}
          onClose={() => setDetailsModal({ opened: false, data: {} })}
        />
      </ModalComp>
      {/* <FilterModal
        builderData={getDataHeaderQuery?.data}
        builderDataLoading={getDataHeaderQuery?.isLoading}
        opened={filter?.modal}
        onClose={() => setFilter({ modal: false })}
        title={"Filters"}
        pagination={pagination}
        setPagination={(d) => {
          const { filter: filterData, ...restData } = d || {};
          setPagination({ ...restData, filter: [...defaultFilter, ...filterData] })
        }}
        value = {filter}
        setValue = {setFilter}
        span={6}
      /> */}
    </Body>
  )
}

const RetrievalModal = ({ journeyDetails, itemsList, callback, onClose }) => {
  const [items, setItems] = useState([]);

  const retrieveMutation = useMutation({
    mutationFn: body => createNewRecord({ body, source: target }),
    onSuccess: () => {
      callback({
        success: true
      });
    },
    onError: (e) => {
      callback({
        success: false
      });
    }
  })

  const saveJourneyLegInventoryMutation = useMutation({
    mutationFn: body => bulkUpsert({ source: 'journey_leg_inventory', body }),
    onSuccess: (r) => {
      console.log(r)
    },
    onError: (e) => {
      console.log(e);
    }
  })

  useEffect(() => {
    if (journeyDetails?.status === 'pending') {
      const d = transformItemDetails(itemsList);
      setItems(d);
    } else {
      setItems(itemsList);
    }
  }, [itemsList]);

  const handleSubmit = () => {
    const inventoryBody = items?.map(item => ({
      id: item.id,
      actual_retrieved: item.actual_retrieved ?? item.closing_scc + item.closing_cc
    }));
    saveJourneyLegInventoryMutation.mutate(inventoryBody, {
      onSuccess: () => {
        const body = {
          journey_leg_id: journeyDetails?.id,
          journey_id: journeyDetails?.journey_id
        }
        retrieveMutation.mutate(body);
      },
      onError: (e) => {
        console.log(e);
        displayNotification({
          message: 'Unable to retrieve. Try again later',
          variant: 'error'
        });
      }
    });
  }

  const handleChange = (index, val) => {
    console.log(index, val);
    setItems(old => {
      const newList = [...old];
      const d = {
        ...newList?.[index],
        actual_retrieved: val
      };

      newList.splice(index, 1, d);
      return newList;
    })
  }
  const totalRetrieved = items?.reduce((acc, obj) => acc + parseInt(obj?.actual_retrieved || 0), 0)

  return (
    <>
      <Grid>
        <Grid.Col span={4}>
          <DisplayField label={'Journey Date'} value={dayjs(journeyDetails?.journey_date).format('DD-MMM-YY')} />
        </Grid.Col>
        <Grid.Col span={4}>
          <DisplayField label={'Aircraft'} value={journeyDetails?.aircraft} />
        </Grid.Col>
        <Grid.Col span={4}>
          <DisplayField label={'Sector Pattern'} value={journeyDetails?.sector_pattern} />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={4}>
          <DisplayField label={'Flight Number'} value={journeyDetails?.flight_number} />
        </Grid.Col>
        <Grid.Col span={4}>
          <DisplayField label={'From'} value={journeyDetails?.from} />
        </Grid.Col>
        <Grid.Col span={4}>
          <DisplayField label={'To'} value={journeyDetails?.to} />
        </Grid.Col>
      </Grid>
      <Table mt={'sm'} withTableBorder>
        <Table.Thead>
          <Table.Tr>
            <Table.Th w={50}>#</Table.Th>
            <Table.Th>Product</Table.Th>
            <Table.Th>Quantity</Table.Th>
            <Table.Th w={90}>Retrieved</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {
            items?.map((item, index) => {
              return (
                <Table.Tr key={index}>
                  <Table.Td>{index + 1}</Table.Td>
                  <Table.Td>{item?.product_id?.product_name}</Table.Td>
                  <Table.Td>{item?.closing_scc + item?.closing_cc}</Table.Td>
                  <Table.Td>
                    {
                      journeyDetails?.status === 'pending'
                        ? (
                          <NumberInputComp
                            min={0}
                            setValue={val => handleChange(index, val)}
                            value={item?.actual_retrieved}
                          />
                        ) : item?.actual_retrieved
                    }
                  </Table.Td>
                </Table.Tr>
              )
            })
          }
          <Table.Tr>
            <Table.Th></Table.Th>
            <Table.Th>Total</Table.Th>
            <Table.Th>{journeyDetails?.quantity}</Table.Th>
            <Table.Th pl={'lg'}>{totalRetrieved}</Table.Th>
          </Table.Tr>
        </Table.Tbody>
      </Table>
      <Group justify='flex-start' mt={'md'} display={journeyDetails?.status === 'pending' ? 'flex' : 'none'}>
        <Flex direction={'row'} gap={'md'} w={'100%'}>
          <Button
            flex={1}
            leftSection={<IconCheck size={16} />}
            onClick={handleSubmit}
            loading={saveJourneyLegInventoryMutation.isPending || retrieveMutation.isPending}
          >
            Retrieve
          </Button>
          <Button
            flex={1}
            color='gray'
            variant='light'
            disabled={saveJourneyLegInventoryMutation.isPending || retrieveMutation.isPending}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Flex>
      </Group>
    </>
  )
}

export default Retrievals
