import React from 'react';
import { Paper, Title, Divider, Stack, Group, Text, Container, PaginationPrevious, Avatar, Grid, GridCol, Box, Flex } from '@mantine/core';
import useAuthStore from '../../store/authStore';
import { COLORS } from '../../constants/colors';

function Profile() {
  // const storedAuth = localStorage.getItem('@_aim_auth_');
  const authData = useAuthStore(state => state.auth)

  if (!authData) {
    return <div>No data available. Please log in.</div>;
  }

  const {
    emp_name,
    emp_code,
    emp_role_id: { role_name } = {},
    emp_category_id: { category_name } = {},
    joining_date,
    is_active,
    last_date,
    email,
    mobile,
  } = authData;

  return (
    <>
      <Box style={{ width: '100%', display: "flex", justifyContent: 'center' }}>
        <Paper style={{ width: '70%', height: "80vh", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar
            color="blue"
            style={{
              width: '15%',
              height: '28%',
              margin: '10px',

            }}
          >
            <Text style={{ fontSize: '50px', fontWeight: 'bold' }}>{emp_name[0]}</Text>
          </Avatar>
          <Divider />


          <div style={{ width: '60%', position: 'relative', left: '10%', marginTop: '20px', padding: '10px' }}>
            <Stack spacing="" style={{ flex: 1, width: '100%' }}>
              <Grid>
                <Grid.Col item span={6}>
                  <Text size='sm' c={COLORS.textColor.darkGray}>Name:</Text>
                  <Text style={{ fontSize: '20px' }}>{emp_name || 'Not Provided'}</Text>
                </Grid.Col>


                <Grid.Col span={6}>
                  <Text size='sm' c={COLORS.textColor.darkGray}>Employee Code:</Text>
                  <Text style={{ fontSize: '20px' }}>{emp_code || 'Not Provided'}</Text>
                </Grid.Col>
              </Grid>
              <Grid>

                <Grid.Col span={6}>
                  <Text size='sm' c={COLORS.textColor.darkGray}>Email:</Text>
                  <Text style={{ fontSize: '20px' }}>{email || '-'}</Text>
                </Grid.Col>


                <Grid.Col span={6} >
                  <Text size='sm' c={COLORS.textColor.darkGray}>Mobile:</Text>
                  <Text style={{ fontSize: '20px' }}>{mobile || '-'}</Text>
                </Grid.Col>
              </Grid>
              <Grid>

                <Grid.Col span={6}>
                  <Text size='sm' c={COLORS.textColor.darkGray}>Role:</Text>
                  <Text style={{ fontSize: '20px' }}>{role_name || '-'}</Text>
                </Grid.Col>


                <Grid.Col span={6}>
                  <Text size='sm' c={COLORS.textColor.darkGray}>Category:</Text>
                  <Text style={{ fontSize: '20px' }}>{category_name || '-'}</Text>
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col span={6} >
                  <Text size='sm' c={COLORS.textColor.darkGray}>Joining date:</Text>
                  <Text style={{ fontSize: '20px' }}>{joining_date || '-'}</Text>
                </Grid.Col>

                <Grid.Col span={6}>
                  <Text size='sm' c={COLORS.textColor.darkGray}>Active:</Text>
                  <Text style={{ fontSize: '20px' }}>{is_active ? 'Active' : '-'}</Text>
                </Grid.Col>
              </Grid>

            </Stack>
          </div>
        </Paper>
      </Box>

    </>
  );
}

export default Profile;