import { createColumnHelper } from '@tanstack/react-table';
import { changeValueFormat } from './format.utils';

const columnHelper = createColumnHelper();

/*
* this function is used to generate the headers 
* @props
* data: contains the array of object with the keys of [name, key, type]
*/
export const generateReportHeaders = (data) => {
  return data?.filter((item) => !item?.hidden)?.map((item) => {
    // console.log('>>>', item, item?.field)
    return columnHelper.accessor(item?.field, {
      header: item?.label,
      enableSorting: true,
      sortingFn: 'NA',
      cell: (value) => {
        const v = (typeof value?.getValue() == 'object' ? value?.getValue()?.[JSON.parse(item?.display_options)?.[0]] : value?.getValue())
        return changeValueFormat(v, item?.type);
      }
    })
  });
}