import {
  ActionIcon,
  Box,
  Button,
  Group,
  Modal,
  Skeleton,
  Table,
  Text,
  Title
} from '@mantine/core'
import { compareObject } from '../../../utils/objectManuplation.utils'
import { notifications } from '@mantine/notifications'
import {
  IconAlertCircle,
  IconCheck,
  IconExclamationCircle,
  IconEye,
  IconPlus,
  IconTrash,
  IconX
} from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  bulkUpsert,
  deleteRecord,
  getAllData,
  refreshSectorPattern
} from '../../../services/common.service'
import { useParams } from 'react-router-dom'
import SelectComp from '../../../commonComponents/components/select/SelectComp'
import TimeInputComp from '../../../commonComponents/components/dateInput/TimeInputComp'
import TextInputComp from '../../../commonComponents/components/textInput/TextInputComp'

const LegData = ({
  airportsQuery,
  position,
  usersQuery,
  setJourneys,
  totalJourneys,
  aircraftQuery,
  journeysOrderCount,
  activeLeg,
  setActiveLeg,
  onDelete,
  error,
  item
}) => {
  const handleChange = (field, val) => {
    let data = totalJourneys
    data.splice(position - 1, 1, {
      ...item,
      [field]: val
    })
    setJourneys(data)
  }

  const toAirports = airportsQuery.data?.filter(
    item => item?.value !== item?.depart_airport_id
  )

  return (
    <Table.Tr bg={activeLeg === position ? 'primary.1' : ''}>
      <Table.Td>{position}</Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          placeholder={'Aircraft'}
          value={item?.aircraft_id?.toString()}
          error={error?.aircraft_id}
          setValue={e => handleChange('aircraft_id', e)}
          readOnly={Boolean(activeLeg !== position)}
          data={aircraftQuery?.data}
          disabled={true}
        />
      </Table.Td>
      <Table.Td>
        <TextInputComp
          isRequired
          placeholder={'Flight no'}
          value={item?.flight_number}
          error={error?.flight_number}
          setValue={e => handleChange('flight_number', e?.toUpperCase())}
          readOnly={Boolean(activeLeg !== position)}
        />
      </Table.Td>
      <Table.Td>
        <TimeInputComp
          isRequired
          showPicker
          value={item?.departure_time}
          error={error?.departure_time}
          setValue={e => {
            handleChange('departure_time', e?.target?.value)
          }}
          readOnly={Boolean(activeLeg !== position)}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          placeholder={'From'}
          data={airportsQuery.data}
          error={error?.depart_airport_id}
          value={item?.depart_airport_id}
          disabled={position !== 1}
          setValue={val => {
            handleChange('depart_airport_id', val)
          }}
          readOnly={Boolean(activeLeg !== position)}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          placeholder={'To'}
          data={toAirports}
          error={error?.arrive_airport_id}
          value={item?.arrive_airport_id}
          setValue={val => {
            handleChange('arrive_airport_id', val)
            let data = [...totalJourneys]
            if (totalJourneys?.length !== position) {
              data.splice(position, 1, {
                ...data[position],
                depart_airport_id: val
              })
            }
            setJourneys([...data])
          }}
          readOnly={Boolean(activeLeg !== position)}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          placeholder={'SCC'}
          data={usersQuery?.data || []}
          error={error?.senior_cabin_crew_id}
          value={item?.senior_cabin_crew_id}
          setValue={val => handleChange('senior_cabin_crew_id', val)}
          readOnly={Boolean(activeLeg !== position)}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          placeholder={'CC'}
          data={usersQuery?.data || []}
          error={error?.cabin_crew_id}
          value={item?.cabin_crew_id}
          setValue={val => handleChange('cabin_crew_id', val)}
          readOnly={Boolean(activeLeg !== position)}
        />
      </Table.Td>
      <Table.Td>
        <ActionIcon
          variant={'subtle'}
          onClick={() => {
            setActiveLeg({ ...item })
          }}
        >
          <IconEye size={16} />
        </ActionIcon>
        {position == 1 ||
        journeysOrderCount?.find(journey => journey?.journey_leg_id == item?.id)
          ?.count_journey_id > 0 ? null : (
          <ActionIcon variant={'subtle'} color={'red'} onClick={onDelete}>
            <IconTrash size={16} />
          </ActionIcon>
        )}
      </Table.Td>
    </Table.Tr>
  )
}

const JourneyLegs = ({
  setActiveLeg,
  activeLeg,
  legData,
  isAddNew,
  setLegData,
  legsQuery,
  aircraftQuery,
  usersQuery,
  originalLegData,
  setOriginalLegData,
  journeysOrderCount
}) => {
  const { journeyId } = useParams()
  const queryClient = useQueryClient()
  // const [journeys, setJourneys] = useState([{}])
  // const [originalJourneys, setOriginalJourneys] = useState([{}])
  const [deleteModal, setDeleteModal] = useState({ modal: false, data: {} })
  const [legError, setLegError] = useState([])

  const airportsQuery = useQuery({
    queryKey: ['all-airports'],
    queryFn: () =>
      getAllData({ source: 'airports', page: -1, filter: { is_active: true } }),
    select: data => {
      return data?.data?.map(items => {
        return {
          ...items,
          label: items?.airport_code,
          value: `${items?.id}`
        }
      })
    }
  })

  const updateSectorPattenQuery = useMutation({
    mutationFn: () => refreshSectorPattern({ journeyId }),
    onSettled: () => {
      queryClient.invalidateQueries([
        'get-journey-order-details',
        'get-journey-details'
      ])
    }
  })

  const deleteLegQuery = useMutation({
    mutationFn: id => deleteRecord({ source: 'journey_leg', id }),
    onSuccess: () => {
      notifications.update({
        id: 'delete1',
        title: 'Journey leg Deleted Successfully',
        message: 'Please wait till we delete the leg',
        color: 'green',
        loading: false,
        autoClose: 3000,
        icon: <IconCheck />
      })
      queryClient.invalidateQueries([
        'get-journey-order-details',
        'get-journey-details'
      ])
    },
    onError: e => {
      notifications.update({
        id: 'delete1',
        title: 'Error',
        message: e?.message || 'Something went wrong',
        color: 'red',
        loading: false,
        autoClose: 3000,
        icon: <IconX />
      })
      queryClient.invalidateQueries([
        'get-journey-order-details',
        'get-journey-details'
      ])
    }
  })

  const saveJourneyMutation = useMutation({
    mutationFn: () => bulkUpsert({ source: 'journey_leg', body: legData }),
    onSuccess: () => {
      notifications.update({
        id: journeyId,
        title: 'Journey Details Updated',
        message: 'Please wait till we update leg info',
        color: 'green',
        loading: false,
        autoClose: 3000,
        icon: <IconCheck />
      })
    },
    onError: e => {
      console.log(e)
      notifications.update({
        id: journeyId,
        title: 'Sorry!',
        message: e || 'Something went wrong',
        color: 'red',
        loading: false,
        autoClose: 3000,
        icon: <IconExclamationCircle />
      })
    },
    onSettled: () => {
      updateSectorPattenQuery.mutate()
    }
  })

  const resetForm = () => {
    const result = legsQuery?.data?.data?.map(item => {
      return {
        id: item.id,
        leg_number: item.leg_number,
        journey_number: item.journey_number,
        is_last_leg: item.is_last_leg,
        flight_number: item.flight_number,
        departure_time: item.departure_time,
        depart_airport_id: String(item.depart_airport_id?.id),
        arrive_airport_id: String(item.arrive_airport_id?.id),
        senior_cabin_crew_id: String(item.senior_cabin_crew_id?.id),
        cabin_crew_id: String(item.cabin_crew_id?.id),
        aircraft_id: String(item.aircraft_id?.id),
        completed_by_scc: item.completed_by_scc,
        retrieved: item.retrieved,
        completed_by_cc: item.completed_by_cc
      }
    })
    setLegData([...result])
    setOriginalLegData([...result])
    setActiveLeg({ ...result?.[0], position: 1 })
    setLegError(Array(legsQuery?.data?.data?.length).fill({}))
  }

  useEffect(() => {
    if (legsQuery?.data?.data?.length) {
      resetForm()
    }
  }, [legsQuery?.data?.data])

  const checkErrorAndSave = () => {
    let isError = false
    let errorResult = []
    legData.forEach(item => {
      let errors = []
      if (!item?.aircraft_id) {
        errors.aircraft_id = 'Select aircraft'
        isError = true
      }
      if (!item?.flight_number) {
        errors.flight_number = 'Enter flight no'
        isError = true
      }
      if (!item?.departure_time) {
        errors.departure_time = 'Enter Departure Time'
        isError = true
      }
      if (!item?.depart_airport_id) {
        errors.depart_airport_id = 'Choose airport'
        isError = true
      }
      if (!item?.arrive_airport_id) {
        errors.arrive_airport_id = 'Choose airport'
        isError = true
      }
      if (!item?.senior_cabin_crew_id) {
        errors.senior_cabin_crew_id = 'Choose SCC'
        isError = true
      }
      if (!item?.cabin_crew_id) {
        errors.cabin_crew_id = 'Choose CC'
        isError = true
      }
      errorResult.push(errors)
    })

    setLegError([...errorResult])
    if (!isError) {
      notifications.show({
        id: journeyId,
        title: 'Updating Journey Legs...',
        message: 'Please do not close anything.',
        loading: true,
        autoClose: false
      })
      saveJourneyMutation.mutate({})
    }
  }

  return (
    <Box>
      <Table verticalSpacing={8} horizontalSpacing={4}>
        <Table.Thead>
          <Table.Tr c='gray.6'>
            <Table.Td>#</Table.Td>
            <Table.Td align='center' w={'8%'}>
              Aircraft
            </Table.Td>
            <Table.Td align='center' w={'10%'}>
              Flight No
            </Table.Td>
            <Table.Td align='center' w={'12%'}>
              Dep. Time
            </Table.Td>
            <Table.Td align='center' w={'10%'}>
              From
            </Table.Td>
            <Table.Td align='center' w={'10%'}>
              To
            </Table.Td>
            <Table.Td align='center' w={'20%'}>
              SCC
            </Table.Td>
            <Table.Td align='center' w={'20%'}>
              CC
            </Table.Td>
            <Table.Td align='center'></Table.Td>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {!legsQuery?.isLoading && legData?.length > 0 ? (
            legData?.map((item, index) => {
              return (
                <LegData
                  airportsQuery={airportsQuery}
                  setJourneys={data => {
                    setLegData([...data])
                  }}
                  onDelete={() =>
                    setDeleteModal({ modal: true, data: item, position: index })
                  }
                  activeLeg={activeLeg?.position}
                  setActiveLeg={data => {
                    let oldObj = {
                      ...originalLegData?.find(
                        ori => ori?.id === activeLeg?.id
                      ),
                      is_last_leg: false
                    }
                    let newObj = {
                      ...legData?.find(ori => ori?.id === activeLeg?.id),
                      is_last_leg: false
                    }
                    console.log('oldObj', oldObj, 'newObj', newObj)

                    if (compareObject(oldObj, newObj)) {
                      setActiveLeg({ ...data, position: index + 1 })
                    } else {
                      notifications.show({
                        title: 'Warning',
                        message:
                          'You have unsaved changes. Please save first or reset it.',
                        color: 'red',
                        icon: <IconAlertCircle />
                      })
                    }
                  }}
                  item={item}
                  aircraftQuery={aircraftQuery}
                  totalJourneys={legData}
                  usersQuery={usersQuery}
                  position={index + 1}
                  key={index}
                  journeysOrderCount={journeysOrderCount}
                  error={legError?.[index]}
                />
              )
            })
          ) : legsQuery?.isLoading ? (
            <Table.Tr>
              <Table.Td>
                <Skeleton h={40} w={'100%'} />
              </Table.Td>
              <Table.Td>
                <Skeleton h={40} w={'100%'} />
              </Table.Td>
              <Table.Td>
                <Skeleton h={40} w={'100%'} />
              </Table.Td>
              <Table.Td>
                <Skeleton h={40} w={'100%'} />
              </Table.Td>
              <Table.Td>
                <Skeleton h={40} w={'100%'} />
              </Table.Td>
              <Table.Td>
                <Skeleton h={40} w={'100%'} />
              </Table.Td>
              <Table.Td>
                <Skeleton h={40} w={'100%'} />
              </Table.Td>
              <Table.Td align='center'>
                <Skeleton h={40} w={'100%'} />
              </Table.Td>
            </Table.Tr>
          ) : (
            <Table.Tr>
              <Table.Td colSpan={8} align='center'>
                <Text>No leg found</Text>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
      {legData?.length < 5 && isAddNew ? (
        <Group justify='center' mt={'md'}>
          <Button
            size='xs'
            leftSection={<IconPlus size={16} />}
            variant='light'
            onClick={() => {
              console.log(Number(legData[legData?.length - 1]?.aircraft_id))

              setLegData([
                ...legData.map((item, index) => ({
                  ...item,
                  is_last_leg: false,
                  leg_number: index + 1
                })),
                {
                  is_last_leg: true,
                  leg_number: legData?.length + 1,
                  journey_id: journeyId,
                  aircraft_id: Number(
                    legData[legData?.length - 1]?.aircraft_id
                  ),
                  depart_airport_id:
                    legData[legData?.length - 1]?.arrive_airport_id
                }
              ])
            }}
          >
            Add New
          </Button>
        </Group>
      ) : null}
      <Modal
        opened={Boolean(deleteModal?.modal)}
        onClose={() => setDeleteModal({ modal: false })}
        title={<Title size={'md'}>Delete Leg</Title>}
      >
        <Text>
          Are you sure you want to delete the below leg?. Confirm to proceed.
        </Text>
        <Text c={'gray'} mt={'xs'}>
          Flight No:{' '}
          <span style={{ color: '#41414199' }}>
            {deleteModal?.data?.flight_number}
          </span>
        </Text>
        <Group justify='flex-end' mt={'md'}>
          <Button
            size='xs'
            variant='light'
            color={'gray'}
            onClick={() => setDeleteModal({ modal: false })}
          >
            Cancel
          </Button>
          <Button
            size='xs'
            onClick={() => {
              if (deleteModal?.data?.id) {
                notifications.show({
                  id: 'delete1',
                  title: 'Deleting Leg...',
                  message: 'Please do not close anything.',
                  loading: true,
                  autoClose: false
                })
                deleteLegQuery.mutate(deleteModal?.data?.id)
              } else {
                let data = [...legData]
                data.splice(deleteModal?.position, 1)
                setLegData(
                  data?.map((item, index) => ({
                    ...item,
                    is_last_leg: index === data?.length - 1
                  }))
                )
              }
              setDeleteModal({ modal: false })
            }}
          >
            Confirm
          </Button>
        </Group>
      </Modal>

      {!compareObject(
        originalLegData?.[activeLeg?.position - 1],
        legData?.[activeLeg?.position - 1]
      ) ? (
        <Group justify='flex-end' mt={'md'}>
          <Button size='xs' variant='outline' color='gray' onClick={resetForm}>
            Reset
          </Button>
          <Button
            size='xs'
            color='teal'
            onClick={checkErrorAndSave}
            loading={saveJourneyMutation?.isPending}
          >
            Save
          </Button>
        </Group>
      ) : null}
    </Box>
  )
}

export default JourneyLegs
