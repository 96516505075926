import {
  ActionIcon,
  Box,
  Button,
  Group,
  Skeleton,
  Table,
  Text
} from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { IconAlertCircle, IconPlus, IconX } from '@tabler/icons-react'
import SelectComp from '../../../commonComponents/components/select/SelectComp'
import TextInputComp from '../../../commonComponents/components/textInput/TextInputComp'
import { memo } from 'react'

const consumed_by = ['A', 'C', 'D', 'F']
  ?.map(item => {
    return Array(18)
      .fill(0)
      ?.map((_, index) => {
        return `${index + 1}${item}`
      })
  })
  .flat()

const CustomerSalesBody = ({
  allProducts,
  allPaymentReason,
  setCustomerSalesData,
  customerSalesData,
  isReadOnly,
  error,
  item,
  index
}) => {
  const handleChange = (field, val) => {
    let data = customerSalesData
    data.splice(index, 1, {
      ...item,
      [field]: val
    })
    setCustomerSalesData(data)
  }
  return (
    <Table.Tr
      onClick={() => {
        if (isReadOnly) {
          notifications.show({
            title: 'Warning',
            message:
              'There are some leg changes not saved. Please save it or reset it.',
            color: 'red',
            icon: <IconAlertCircle />
          })
        }
      }}
    >
      <Table.Td>{parseInt(index) + 1}</Table.Td>
      <Table.Td>
        <SelectComp
          options={[
            { label: 'SCC', value: 'SCC' },
            { label: 'CC', value: 'CC' }
          ]}
          value={item?.journey_order_id?.sold_by?.toUpperCase()}
          setValue={e => {
            let data = customerSalesData
            data.splice(index, 1, {
              ...item,
              journey_order_id: {
                ...item?.journey_order_id,
                sold_by: e
              }
            })
            setCustomerSalesData(data)
          }}
          error={error?.sold_by}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          options={['Sales', 'FOC']}
          value={item?.journey_order_id?.sales_type}
          setValue={e => {
            let data = customerSalesData
            data.splice(index, 1, {
              ...item,
              journey_order_id: {
                ...item?.journey_order_id,
                sales_type: e
              },
              payment_exception_reasons: null
            })
            setCustomerSalesData(data)
            console.log(data)
          }}
          error={error?.sales_type}
          allowDeselect={false}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          options={
            allPaymentReason?.data?.filter(
              item => !item?.for_internal_employees
            ) || []
          }
          value={item?.payment_exception_reasons || null}
          setValue={e => handleChange('payment_exception_reasons', e)}
          error={error?.payment_exception_reasons}
          disabled={item?.journey_order_id?.sales_type === 'Sales'}
          readOnly={
            isReadOnly || item?.journey_order_id?.sales_type === 'Sales'
          }
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          value={item?.journey_order_id?.seat}
          options={consumed_by}
          setValue={e => {
            let data = customerSalesData
            data.splice(index, 1, {
              ...item,
              journey_order_id: {
                ...item?.journey_order_id,
                seat: e
              }
            })
            setCustomerSalesData(data)
          }}
          error={error?.seat}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          data={allProducts?.data || []}
          value={item?.product_id?.id?.toString()}
          setValue={(e, option) => {
            let data = customerSalesData
            data.splice(index, 1, {
              ...item,
              quantity: item?.quantity || 1,
              product_id: {
                ...option
              }
            })
            setCustomerSalesData(data)
          }}
          error={error?.product_id}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <TextInputComp
          value={item?.quantity}
          onChange={e => {
            handleChange('quantity', Number(e.target.value))
          }}
          error={error?.quantity}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <TextInputComp
          leftSection='₹'
          value={item?.product_id?.selling_price}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <TextInputComp
          leftSection='₹'
          value={
            parseInt(item?.quantity || 0) *
            parseInt(item?.product_id?.selling_price || 0)
          }
        />
      </Table.Td>
      {console.log(item?.journey_order_id?.invoice_email)}
      <Table.Td>
        <TextInputComp
          value={item?.journey_order_id?.invoice_email || ''}
          setValue={e => {
            let data = customerSalesData
            data.splice(index, 1, {
              ...item,
              journey_order_id: {
                ...item?.journey_order_id,
                invoice_email: e
              }
            })
            setCustomerSalesData(data)
          }}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        {!item?.id ? (
          <ActionIcon
            variant='subtle'
            color='red'
            onClick={() => {
              let data = customerSalesData
              data.splice(index, 1)
              setCustomerSalesData([...data])
            }}
          >
            <IconX size={18} />
          </ActionIcon>
        ) : null}
      </Table.Td>
    </Table.Tr>
  )
}

// eslint-disable-next-line react/display-name
const CustomerOrders = memo(
  ({
    allProducts,
    allPaymentReason,
    customerSalesData,
    setCustomerSalesData,
    errorsObj,
    getCustomerSalesDetailsQuery,
    addNewCustomerSales,
    isReadOnly
  }) => {
    // const saveJourneyMutation = useMutation({
    //   mutationFn: body =>
    //     hierarchicalInsert({
    //       source: ['journey_order_item', 'journey_order'],
    //       body
    //     }),
    //   onSuccess: () => {
    //     notifications.update({
    //       id: journeyId,
    //       title: 'Order Details Updated',
    //       message: 'Please wait till we update Orders',
    //       color: 'green',
    //       loading: false,
    //       autoClose: 3000,
    //       icon: <IconCheck />
    //     })
    //   },
    //   onError: e => {
    //     console.log(e)
    //     notifications.update({
    //       id: journeyId,
    //       title: 'Sorry!',
    //       message: e || 'Something went wrong',
    //       color: 'red',
    //       loading: false,
    //       autoClose: 3000,
    //       icon: <IconExclamationCircle />
    //     })
    //   }
    // })

    // const checkErrorAndSave = () => {
    //   if (isReadOnly) {
    //     notifications.show({
    //       title: 'Warning',
    //       message:
    //         'There are some leg changes not saved. Please save it or reset it.',
    //       color: 'red',
    //       icon: <IconAlertCircle />
    //     })
    //     return
    //   }
    //   let isError = false
    //   let resultError = []
    //   customerSalesData.forEach(item => {
    //     let error = {}
    //     if (!item?.journey_order_id?.sold_by) {
    //       error.sold_by = 'Please select sold by'
    //       isError = true
    //     }
    //     if (!item?.journey_order_id?.seat) {
    //       error.seat = 'Please select seat'
    //       isError = true
    //     }
    //     if (
    //       item?.journey_order_id?.sales_type === 'FOC' &&
    //       !item?.payment_exception_reason
    //     ) {
    //       error.payment_exception_reason = 'Please select reason'
    //       isError = true
    //     }
    //     if (!item?.journey_order_id?.sales_type) {
    //       error.sales_type = 'Please select Type'
    //       isError = true
    //     }
    //     if (!item?.product_id?.id) {
    //       error.product_id = 'Please select product'
    //       isError = true
    //     }
    //     if (!item?.quantity) {
    //       error.quantity = 'Please enter quantity'
    //       isError = true
    //     }
    //     if (isError) {
    //       resultError.push(error)
    //     }
    //   })
    //   setErrorsObj([...resultError])
    //   if (!isError) {
    //     notifications.show({
    //       id: journeyId,
    //       title: 'Updating Orders...',
    //       message: 'Please do not close anything.',
    //       loading: true,
    //       autoClose: false
    //     })
    //     let result = groupObjectUsingKeys({
    //       data: customerSalesData?.map(item => ({
    //         ...item,
    //         sold_by: item?.journey_order_id?.sold_by,
    //         seat: item?.journey_order_id?.seat,
    //         sales_type: item?.journey_order_id?.sales_type
    //       })),
    //       keysArray: ['sold_by', 'seat', 'sales_type']
    //     })
    //     console.log(Object.values(result))

    //     let body = Object.values(result)?.map(value => ({
    //       journey_order: {
    //         ...value[0]?.journey_order_id,
    //         journey_id: Number(journeyId),
    //         journey_leg_id: activeLeg?.id,
    //         internal_sales: Boolean(value?.[0]?.journey_order_id?.internal_sales),
    //         journey_order_item: value?.map(item => ({
    //           id: item?.id,
    //           journey_id: journeyId,
    //           journey_leg_id: activeLeg?.id,
    //           product_id: item?.product_id?.id,
    //           journey_order_id: item?.journey_order_id?.id,
    //           product_name: item?.product_id.label,
    //           product_category:
    //             item?.product_id.category_id?.id || item?.product_id.category_id,
    //           product_code: item?.product_id.product_code,
    //           payment_exception_reason: item?.payment_exception_reason,
    //           quantity: item?.quantity,
    //           unit_price: item?.product_id.selling_price,
    //           created_by: item?.created_by?.id,
    //           updated_by: item?.updated_by?.id
    //         }))
    //       }
    //     }))
    //     console.log(body)

    //     saveJourneyMutation.mutate(body)
    //   }
    // }

    return (
      <Box>
        <Table verticalSpacing={8} horizontalSpacing={4}>
          <Table.Thead>
            <Table.Tr c='gray.6'>
              <Table.Td>#</Table.Td>
              <Table.Td w={'8%'}>SCC/CC</Table.Td>
              <Table.Td w={'8%'}>Sales/FOC</Table.Td>
              <Table.Td w={'12%'}>Reason for FOC</Table.Td>
              <Table.Td w={'10%'}>Consumed By</Table.Td>
              <Table.Td w={'12%'}>Product Name</Table.Td>
              <Table.Td w={'8%'}>QTY</Table.Td>
              <Table.Td w={'10%'}>Price</Table.Td>
              <Table.Td w={'10%'}>Total</Table.Td>
              <Table.Td w={'16%'}>Invoice</Table.Td>
              <Table.Td></Table.Td>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {!getCustomerSalesDetailsQuery?.isLoading &&
            customerSalesData?.length > 0 ? (
              customerSalesData?.map((item, index) => {
                return (
                  <CustomerSalesBody
                    allProducts={allProducts}
                    allPaymentReason={allPaymentReason}
                    item={item}
                    index={index}
                    error={errorsObj?.[index]}
                    customerSalesData={customerSalesData}
                    setCustomerSalesData={data => {
                      setCustomerSalesData([...data])
                    }}
                    isReadOnly={isReadOnly}
                    key={index}
                  />
                )
              })
            ) : getCustomerSalesDetailsQuery?.isLoading ||
              getCustomerSalesDetailsQuery?.isPending ? (
              <Table.Tr>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
              </Table.Tr>
            ) : (
              <Table.Tr>
                <Table.Td colSpan={11} align='center'>
                  <Text c={'dimmed'}>No records found</Text>
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
        <Group justify='center' mt={'md'}>
          <Button
            size='xs'
            leftSection={<IconPlus size={16} />}
            variant='light'
            onClick={addNewCustomerSales}
          >
            Add New
          </Button>
        </Group>
      </Box>
    )
  }
)

export default CustomerOrders
