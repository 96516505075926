import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import { displayNotification } from '../../commonComponents/notifications/displayNotification'

import Body from '../../commonComponents/body/Body'
import ReactTable from '../../commonComponents/reactTable/ReactTable'
import { generateReportHeaders } from '../../utils/table.utils'
import { createNewRecord, fileUploadAPI, getAllData, getHeaders } from '../../services/common.service'
import HeaderWithSearch from '../../commonComponents/components/headersWithSearch/HeaderWithSearch'
import { ActionIcon, Button, Group, Tooltip } from '@mantine/core'
import { getFormBuilderData } from '../../services/master.service'
import { useDisclosure } from '@mantine/hooks'
import { IconFilter, IconPlus } from '@tabler/icons-react'
import NewGRNForm from './NewGRNForm'
import { clearGrnStore } from '../../store/grnStore'
import FilterModal from '../../commonComponents/filter/FilterModal'
import { COLORS } from '../../constants/colors'

const source = 'grn_products';
let defaultSorting = { id: 'grn_entries_id', desc: true };
const transformFields = data => {
  return data?.[0]
}
const GRN = () => {
  const [pagination, setPagination] = useState({ page: 1, search: '', source, sort: [defaultSorting] })
  // const [editModal, setEditModal] = useState()
  // const [filterOpened, { toggle: toggleFilter }] = useDisclosure(false)
  const [newFormOpened, { toggle: toggleNewForm }] = useDisclosure(false)
  const [filter, setFilter] = useState({})

  const allRecordsQuery = useQuery({
    queryKey: ['all-records', source, pagination],
    queryFn: () => getAllData(pagination)
  })

  const tableHeadersQuery = useQuery({
    queryKey: ['table-headers', source],
    queryFn: () => getHeaders({ source }),
    select: data => {
      return { headers: generateReportHeaders(data?.[0]), data: data?.[0] }
    }
  })

  const getDataHeaderQuery = useQuery({
    queryKey: ['formBuilders-fields', source],
    queryFn: () => getFormBuilderData({ tableName: source }),
    select: transformFields
  })

  useEffect(() => {
    if (pagination?.search) {
      setPagination(old => ({ ...old, source, page: 1 }))
    }
  }, [pagination?.search])

  const handleSorting = e => {
    // find the sorting data in the array from previous state
    const d = e(pagination.sort);
    setPagination(old => ({ ...old, sort: d }));
  }

  return (
    <Body>
      <Group justify='space-between'>
        <HeaderWithSearch
          title={'GRN Entries'}
          pagination={pagination}
          setPagination={setPagination}
        />
        <Group gap={14}>
          <Tooltip
            withArrow
            position='top'
            label={'Open Filters'}
          >
            <ActionIcon
              variant='outline'
              color={COLORS.green}
              size={'30'}
              // size={'sm'}
              // variant='light'
              // onClick={toggleFilter}
              onClick={() => setFilter({ modal: true })}
            >
              <IconFilter strokeWidth={1.5} />
            </ActionIcon>
          </Tooltip>
          <Button
            size='xs'
            rightSection={<IconPlus size={16} />}
            onClick={toggleNewForm}
          >
            Add New
          </Button>
        </Group>
      </Group>
      <ReactTable
        sorting={pagination.sort}
        setSorting={handleSorting}
        columnData={tableHeadersQuery?.data?.headers}
        loading={
          allRecordsQuery?.isLoading || tableHeadersQuery?.isLoading
        }
        // onRowClick={e => setEditModal({ data:e, modal: true})}
        totalNoOfPages={
          allRecordsQuery?.data?.metadata?.total_number_of_page
        }
        totalNoOfRecords={allRecordsQuery?.data?.metadata?.records}
        rowData={allRecordsQuery?.data?.data}
        page={pagination?.page}
        setPage={page => setPagination({ ...pagination, page })}
      />

      <NewGRNForm
        opened={newFormOpened}
        onClose={() => {
          clearGrnStore();
          toggleNewForm();
        }}
        onSuccess={() => {
          // displayNotification({
          //   message: 'GRN created successfully',
          //   variant: 'success',
          // })
          allRecordsQuery?.refetch()
          toggleNewForm()
        }}
      />
      <FilterModal
        builderData={getDataHeaderQuery?.data}
        builderDataLoading={getDataHeaderQuery?.isLoading}
        opened={filter?.modal}
        onClose={() => setFilter({ modal: false })}
        title={"Filters"}
        pagination={pagination}
        setPagination={setPagination}
        value = {filter}
        setValue = {setFilter}
        span={6}
      />
      
    </Body>
  )
}

export default GRN
