import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import { displayNotification } from '../../commonComponents/notifications/displayNotification'

import Body from '../../commonComponents/body/Body'
import ReactTable from '../../commonComponents/reactTable/ReactTable'
import { generateReportHeaders } from '../../utils/table.utils'
import {
  createNewRecord,
  fileUploadAPI,
  getAllData,
  getHeaders
} from '../../services/common.service'
import {
  ActionIcon,
  Button,
  Drawer,
  Group,
  Indicator,
  Title,
  Tooltip
} from '@mantine/core'
import HeaderWithSearch from '../../commonComponents/components/headersWithSearch/HeaderWithSearch'
import { IconEdit, IconFilter, IconPencil, IconPlus } from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'
import NewJourneyForm from './NewJourneyForm'
import EditJourneyForm from './EditJourneyForm'
import EditSalesForm from './JourneyEditSearch'
import { COLORS } from '../../constants/colors'
import FilterModal from '../../commonComponents/filter/FilterModal'
import { getFormBuilderData } from '../../services/master.service'

const source = 'journey_leg'
const defaultSorting = [
  { id: 'journey_date', desc: true },
  { id: 'sector_pattern', desc: false },
  { id: 'leg_number', desc: false }
]
const transformFields = data => {
  return data?.[0]
}
const Journeys = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    search: '',
    source,
    sort: defaultSorting
  })
  const [editModal, setEditModal] = useState()
  const [filterOpened, { toggle: toggleFilter }] = useDisclosure(false)
  const [newFormOpened, { toggle: toggleNewForm }] = useDisclosure(false)
  const [salesFormOpened, { toggle: toggleSalesForm }] = useDisclosure(false)
  const [filter, setFilter] = useState({})

  const allRecordsQuery = useQuery({
    queryKey: ['all-records', source, pagination],
    queryFn: () => getAllData(pagination)
  })

  const tableHeadersQuery = useQuery({
    queryKey: ['table-headers', source],
    queryFn: () => getHeaders({ source }),
    select: data => {
      return { headers: generateReportHeaders(data?.[0]), data: data?.[0] }
    }
  })

  const getDataHeaderQuery = useQuery({
    queryKey: ['formBuilders-fields', source],
    queryFn: () => getFormBuilderData({ tableName: source }),
    select: transformFields
  })

  useEffect(() => {
    if (pagination?.search) {
      setPagination(old => ({ ...old, page: 1 }))
    }
  }, [pagination?.search])

  const handleSorting = e => {
    // find the sorting data in the array from previous state
    const d = e(pagination.sort)
    setPagination(old => ({ ...old, sort: d }))
  }

  return (
    <Body>
      <Group justify='space-between'>
        <HeaderWithSearch
          title={'Journeys'}
          pagination={pagination}
          setPagination={setPagination}
        />
        <Group gap={14}>
          <Tooltip withArrow position='top' label={'Open Filters'}>
            <Indicator
              inline
              disabled={!Object.entries(filter?.data || {})?.length}
              color='red'
              size={12}
            >
              <ActionIcon
                variant='outline'
                color={COLORS.green}
                size={'30'}
                onClick={() => setFilter({ modal: true })}
              >
                <IconFilter strokeWidth={1.5} />
              </ActionIcon>
            </Indicator>
          </Tooltip>
          <Button
            size='xs'
            rightSection={<IconPlus size={16} />}
            onClick={toggleNewForm}
          >
            Add New
          </Button>
          <Button
            size='xs'
            variant='outline'
            rightSection={<IconPencil size={16} />}
            onClick={toggleSalesForm}
          >
            Edit Sales
          </Button>
        </Group>
      </Group>
      <ReactTable
        sorting={pagination.sort}
        setSorting={handleSorting}
        columnData={tableHeadersQuery?.data?.headers}
        loading={allRecordsQuery?.isLoading || tableHeadersQuery?.isLoading}
        onRowClick={e => setEditModal({ data: e, modal: true })}
        totalNoOfPages={allRecordsQuery?.data?.metadata?.total_number_of_page}
        totalNoOfRecords={allRecordsQuery?.data?.metadata?.records}
        rowData={allRecordsQuery?.data?.data}
        page={pagination?.page}
        setPage={page => setPagination({ ...pagination, page })}
      />
      {/* New Journey Section */}
      <NewJourneyForm
        opened={newFormOpened}
        onClose={toggleNewForm}
        onSuccess={() => {
          toggleNewForm()
          allRecordsQuery?.refetch()
        }}
      />
      <EditSalesForm
        opened={salesFormOpened}
        onClose={toggleSalesForm}
        onSuccess={() => {
          toggleSalesForm()
          allRecordsQuery?.refetch()
        }}
      />
      {/* Edit Journey Section */}
      {editModal?.modal && (
        <EditJourneyForm
          data={editModal?.data}
          opened={editModal?.modal}
          onClose={() => setEditModal({ data: {}, modal: false })}
          onSuccess={() => {
            setEditModal({ data: {}, modal: false })
            allRecordsQuery?.refetch()
          }}
        />
      )}
      <FilterModal
        builderData={getDataHeaderQuery?.data}
        builderDataLoading={getDataHeaderQuery?.isLoading}
        opened={filter?.modal}
        onClose={() => setFilter({ modal: false })}
        title={'Filters'}
        pagination={pagination}
        setPagination={setPagination}
        value={filter}
        setValue={setFilter}
        span={6}
      />
    </Body>
  )
}

export default Journeys
